import React from 'react';
import { withRouter } from 'react-router';
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonBadge,
  IonMenuToggle,
} from '@ionic/react';
import { scan, basket, arrowBack } from 'ionicons/icons';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import Icon from '../icon';
import { SmallText, Title } from '../common';
import { forwardTo, getRouteClassName, isWebConfig, goBack } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { getConfig } from '../../appConfig';
import menuButton from '../../assets/images/menu-button.svg';
import './index.css';

const StaticHeader = ({__, history, backHandler, title, headerWithTitle, navConfig}) => {
  const currentPath = history.location.pathname;
  const config = getConfig();
  const authPages = config.general.authRoutes.indexOf(currentPath) !== -1;
  const pagesWithBackButton = config.general.routesWithBackButton.indexOf(currentPath) !== -1;
  const hideStaticHeader = config.theme.routesWithoutStaticHeader.indexOf(currentPath) !== -1;
  const routeClassName = getRouteClassName(currentPath,navConfig);
  
  return (
    <>
      {hideStaticHeader ? null : config.theme.showHeaderOnAuthRoutes || !authPages ? (
        <IonHeader className={'static ' + routeClassName}>
          <IonToolbar className={`${headerWithTitle ? 'no-logo' : ''} primary-toolbar`}>
            <IonButtons slot="start">
              {!authPages && !pagesWithBackButton ? (
                !isWebConfig() ? (
                  <IonMenuToggle>
                    <img className="menu-button" src={menuButton} alt=' '></img>
                  </IonMenuToggle>
                ) : null
              ) : (
                <IonButton className="header-back-button" onClick={() => backHandler ? backHandler() : goBack()}>
                  <Icon color='dark' icon={arrowBack} className="icon" />
                </IonButton>
              )}
            </IonButtons>
            {!headerWithTitle ?
              <IonButtons color="primary">
                <IonButton
                  className="image-button"
                  color="primary-shade"
                  onClick={() => forwardTo('/dashboard')}
                />
              </IonButtons>
              :
              <IonButton className='header-title' onClick={() => forwardTo('/dashboard')}>
                <Title >{title}</Title>
              </IonButton>
            }

            <IonButtons slot="end">
              {Basket.itemsCount() > 0 || isWebConfig() ? (
                <IonButton
                  disabled={Basket.itemsCount() > 0 ? false : true}
                  color="dark"
                  className="basket-button"
                  onClick={() => forwardTo('/order-summary')}
                  size={24}
                >
                  <div>
                    <div className="basket-icon-wrapper">
                      <IonBadge
                        slot="end"
                        color="secondary"
                        className={Basket.itemsCount() >= 10 ? 'badge' : 'badge-small'}
                      >
                        {Basket.itemsCountAll()}
                      </IonBadge>
                      <Icon color='dark' icon={basket} classname="icon" />
                    </div>
                    <div>
                      <SmallText color="gray">{Basket._getTotal()}</SmallText>
                    </div>
                  </div>
                </IonButton>
              ) : !authPages && !isWebConfig() ? (
                config.appType.hasLoyalty ? (
                  <IonButton
                    color="dark"
                    button
                    clear
                    onClick={() => forwardTo('/loyalty', { tab: 'scan' })}
                  >
                    <IonIcon color='dark' slot="icon-only" icon={scan} />
                  </IonButton>
                ) : null
              ) : null}
            </IonButtons>
          </IonToolbar>
          <IonToolbar color="transparent" />
        </IonHeader>
      ) : null}
    </>
  );
};

const stateToProps = (store) => {
  const { orders } = store;
  const { navConfig} = store.common;
  return {
    basketUpdated: orders.basketUpdated,
    navConfig
  };
};

export default connect(stateToProps)(withRouter(withTranslation(StaticHeader)));
