import React, { Component } from 'react';
import {
  IonRow,
  IonCol,
  IonButton,
  IonInput,
  IonItem,
  IonTextarea,
  IonIcon,
  IonList,
} from '@ionic/react';
import Checkout from '../checkout';
import { person } from 'ionicons/icons';
import successIcon from '../../assets/images/icons/success-icon.svg';
import { withTranslation } from '../../lib/translate';
import moment from '../../lib/moment';

import {
  BigLabel,
  FieldError,
  NormalText,
  SmallText,
  Spacer,
  StrongText,
  Title,
} from '../../components/common';

import Modal from '../../components/modal';
import { forwardTo, validateForm } from '../../lib/utils';
import Basket from '../../lib/basket';
import { connect } from 'react-redux';
import Mobiscroll from '../../components/mobiscroll';
import { getConfig } from '../../appConfig';
import { getSentGiftVouchers } from '../../store/actions';

const { SelectOption } = Mobiscroll;

class SendGiftVoucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      giftVoucherAmount: 0,
      email: '',
      recipientAdded: false,
      checkoutPage: false,
      voucherSummaryPage: false,
      voucherMessageModalOpen: false,
      giftVoucherMesageFromModal: '',
      giftVoucherMessage: '',
      recipientEmailAddressModalOpen: false,
      selectedGiftVoucher: false,
      sendNewVoucher: false,
      showSuccessSend: false,
      showSuccessResend: false,
      giftVoucherValues: [],
      giftVoucherDate: null,
    };
    this.voucherValidator = {
      email: { type: 'email', required: true },
    };
  }

  resetState(newVoucher) {
    this.setState({
      giftVoucherAmount: this.state.giftVoucherValues[1],
      email: '',
      recipientAdded: false,
      checkoutPage: false,
      voucherSummaryPage: false,
      voucherMessageModalOpen: false,
      giftVoucherMesageFromModal: '',
      giftVoucherMessage: '',
      recipientEmailAddressModalOpen: false,
      selectedGiftVoucher: false,
      sendNewVoucher: newVoucher,
      showSuccessSend: false,
      showSuccessResend: false,
    });
  }
  sendGiftVoucher = () => {
    const { dispatch, restaurants, profile } = this.props;

    const { email } = this.state;
    let voucherErrors = validateForm(this.voucherValidator, { email });
    this.setState({ voucherErrors });
    if (Object.keys(voucherErrors).length === 0) {
      const data = {
        points_value: this.state.giftVoucherAmount * 100,
        sender_name: this.props.profile.first_name,
        recipient_name: '',
        recipient_email: this.state.email,
        personal_message: this.state.giftVoucherMessage,
        send_on_date: this.state.giftVoucherDate
      };
      Basket.reset();
      Basket.setCollectionTime(null);
      Basket.addToBasket({
        item: { productPrice: this.state.giftVoucherAmount, productName: 'Voucher' },
        quantity: 1,
      });
      Basket.setGift();
      Basket.setRestaurant(restaurants.find((restaurant) => restaurant.name === 'Vouchers'));
      Basket.setServicePercentage(0);
      dispatch({
        type: 'SET_GIFT_VOUCHER_DATA',
        value: data,
      });

      if (this.props.auth.loggedIn) {
        this.setState({
          checkoutPage: true,
          voucherSummaryPage: false,
        });
        this.props.checkoutPageHandler();
      } else {
        forwardTo('/login', { fromGiftVoucher: true });
      }
    }
  };
  resendGiftVoucher = () => {
    const { dispatch } = this.props;
    const { email, selectedGiftVoucher, giftVoucherMessage,giftVoucherDate } = this.state;
    let voucherErrors = validateForm(this.voucherValidator, { email });
    if (Object.keys(voucherErrors).length === 0) {
      const data = {
        gift_id: selectedGiftVoucher.id,
        recipient_email: email,
        personal_message: giftVoucherMessage,
        send_on_date: giftVoucherDate,
      };
      dispatch({
        type: 'RESEND_GIFT_VOUCHER',
        data,
      });
    }
  };
  proceedeToOrderSummary = () => {
    const { email } = this.state;
    let voucherErrors = validateForm(this.voucherValidator, { email });
    this.setState({ voucherErrors });
    if (Object.keys(voucherErrors).length === 0) {
      this.setState({ recipientAdded: true, voucherSummaryPage: true });
      this.props.summaryPageHandler();
    }
  };

  drawSendVoucherContent = (__) => {
    return (
      <div className="send-voucher-wrapper">
        <Title className="default-padding">{__('Send gift voucher')}</Title>
        <div className="select-amount-wrapper">
          <NormalText>{__('Select the voucher amount')}</NormalText>
          <BigLabel>{`${Basket.getCurrency().label}${this.state.giftVoucherAmount}`}</BigLabel>
          <div className="actions">
            <IonButton
              onClick={() => {
                this.setState({
                  giftVoucherAmount: this.state.giftVoucherValues[0],
                });
              }}
              color="secondary"
            >
              {`${Basket.getCurrency().label}${this.state.giftVoucherValues[0]}`}
            </IonButton>
            <IonButton
              onClick={() => {
                this.setState({
                  giftVoucherAmount: this.state.giftVoucherValues[1],
                });
              }}
              color="secondary"
            >
              {`${Basket.getCurrency().label}${this.state.giftVoucherValues[1]}`}
            </IonButton>
            <IonButton
              onClick={() => {
                this.setState({
                  giftVoucherAmount: this.state.giftVoucherValues[2],
                });
              }}
              color="secondary"
            >
              {`${Basket.getCurrency().label}${this.state.giftVoucherValues[2]}`}
            </IonButton>
          </div>
        </div>
        <Spacer size={2} />
        <div className="default-padding">
          <NormalText>
            {__(
              'Enter the recipient details below and they will receive a code to redeem in their app.',
            )}
          </NormalText>
          <IonInput
            onIonChange={(e) => this.setState({ email: e.target.value })}
            type="email"
            pattern="email"
            inputmode="email"
            value={this.state.email}
            placeholder="Enter recipient email address"
          ></IonInput>
          {this.state.voucherErrors && this.state.voucherErrors.email && (
            <FieldError className="field-error" value={__(this.state.voucherErrors.email)} />
          )}

          <IonButton
            disabled={this.state.email.length == 0}
            onClick={() => {
              this.proceedeToOrderSummary();
            }}
            className="send-voucher-btn uppercase"
            color="secondary"
          >
            {__('Send Gift Voucher')}
          </IonButton>
          {this.props.sentGiftVouchers.length > 0 && (
            <IonButton
              onClick={() => {
                this.resetState(false);
              }}
              fill="clear"
              expand="block"
              className="link underlined uppercase"
              color="secondary"
            >
              {__('View All Sent Vouchers')}
            </IonButton>
          )}
        </div>
      </div>
    );
  };

  formatVoucherValues = () => {
    const values = this.state.giftVoucherValues;
    const data = values.map((value) => {
      return { text: Basket.formatPrice(value), value };
    });

    return data;
  };

  getDateArray = (obj) => {
    var start = obj.startDate.clone();
    var end = obj.endDate.clone();
    var res = [];
    while (start.isBefore(end)) {
      res.push(start.toDate());
      start.add(1, 'd');
    }
    return res;
  };

  formatDateValues = () => {
    const startDate = moment();
    const endDate = moment().add(30, 'days');
    const daysInBetween = this.getDateArray({ startDate, endDate });
    const data = daysInBetween.map((date) => {
      return {
        text: moment(date).format('DD MMMM YYYY'),
        value: moment(date).format('MM-DD-YYYY'),
      };
    });
    return data;
  };

  drawVoucherOrderSummary = (__, editable, resend) => {
    const { email, giftVoucherAmount, giftVoucherMessage, giftVoucherDate } = this.state;
    const giftVoucherSendDaysLimit = getConfig().general?.giftVoucherSendDaysLimit;
    const numberOfDays = giftVoucherSendDaysLimit ? giftVoucherSendDaysLimit : 30;
    const maxDate = new Date(new Date().setDate(new Date().getDate() + numberOfDays));
    const dateValues = this.formatDateValues();
    return (
      <>
        <div className="order-summary-wrapper">
          <Title className="default-padding">{__('Order Summary')}</Title>
          <Spacer size={1} />

          <div className="gift-voucher-order-content">
            <StrongText>{__('Voucher details')}</StrongText>
            <Spacer size={1} />
            <IonRow>
              <IonCol className="paddLR grow">
                <StrongText>£{giftVoucherAmount.toFixed(2)}</StrongText> {__('Gift voucher')}
                <br />
                {__('recipient')}: <StrongText>{email}</StrongText>
              </IonCol>
              <IonCol className="righted paddLR underlined pointer">
                {editable && (
                  <div
                    onClick={() => {
                      this.setState({ recipientEmailAddressModalOpen: true });
                    }}
                  >
                    {__('Edit')}
                  </div>
                )}
              </IonCol>
            </IonRow>
          </div>
          {giftVoucherMessage == '' ? (
            <>
              {editable && (
                <div>
                  <IonButton
                    onClick={() => {
                      this.setState({ giftVoucherMessageModalOpen: true });
                    }}
                    expand="block"
                    fill="clear"
                    color="primary"
                    className="link underlined"
                  >
                    {__('Add a personal message')}
                  </IonButton>
                </div>
              )}
            </>
          ) : (
            <>
              <Spacer size={1} />
              <div className="personal-message-wrapper">
                <StrongText>{__('Personal Message')}</StrongText>
                <Spacer />
                <IonRow>
                  <IonCol size="9">
                    <NormalText>{giftVoucherMessage}</NormalText>
                  </IonCol>
                  <IonCol className="righted paddLR underlined pointer">
                    {editable && (
                      <div
                        onClick={() => {
                          this.setState({ giftVoucherMessageModalOpen: true });
                        }}
                      >
                        {__('Edit')}
                      </div>
                    )}
                  </IonCol>
                </IonRow>
              </div>
              <Spacer size={1} />
            </>
          )}
          <div className="gift-vouchers-date-wrapper">
            <StrongText className="block">{__('Select date')}</StrongText>
            <Spacer size={1} />
            <SelectOption
              display="center"
              className="gift-vouchers-date-field"
              onSet={(e, a) =>
                this.setState({
                  giftVoucherDate: a.getVal(),
                })
              }
              data={dateValues}
              label="Location"
              value={moment(giftVoucherDate).format('MM-DD-YYYY')}
              cancelText={__('Cancel')}
              setText={__('OK')}
            />
          </div>

          <Spacer />
          {!resend ? (
            <IonButton
              onClick={this.sendGiftVoucher}
              expand="block"
              color='secondary'
              className="uppercase okx-font-secondary default-padding send-voucher-btn"
            >
              {__('Checkout')}
            </IonButton>
          ) : (
            <>
              {editable && this.vaucherIsScheduled(moment(giftVoucherDate)) ? (
                <IonButton
                  onClick={this.resendGiftVoucher}
                  expand="block"
                  className="uppercase okx-font-secondary default-padding"
                >
                  {__('Save Changes')}
                </IonButton>
              ):(
                <IonButton
                  onClick={this.resendGiftVoucher}
                  expand="block"
                  className="uppercase okx-font-secondary default-padding"
                  color="secondary"
                >
                  {__('Resend')}
                </IonButton>
              )
              }
            </>
          )}

          <IonButton
            onClick={() => {
              this.props.detailsPageHandler();
              this.resetState(false);
            }}
            expand="block"
            className="link underline default-padding"
          >
            {__('Cancel')}
          </IonButton>
          <Modal
            onDidDismiss={() =>
              this.setState({
                giftVoucherMessageModalOpen: false,
                giftVoucherMesageFromModal: this.state.giftVoucherMessage,
              })
            }
            className="personal-message-modal"
            isOpen={this.state.giftVoucherMessageModalOpen}
          >
            <Title>{__('Personal message')}</Title>
            <Spacer size={1} />
            <IonItem>
              <IonTextarea
                rows={5}
                placeholder={__('Enter your message')}
                value={this.state.giftVoucherMesageFromModal}
                onIonChange={(event) =>
                  this.setState({ giftVoucherMesageFromModal: event.detail.value })
                }
              ></IonTextarea>
            </IonItem>
            <Spacer size={1} />
            <IonButton
              color="secondary"
              expand="block"
              onClick={() => {
                this.setState({
                  giftVoucherMessage: this.state.giftVoucherMesageFromModal,
                  giftVoucherMessageModalOpen: false,
                });
              }}
            >
              {' '}
              {__('Continue')}
            </IonButton>
          </Modal>
          <Modal
            onDidDismiss={() => this.setState({ recipientEmailAddressModalOpen: false })}
            className="personal-message-modal"
            isOpen={this.state.recipientEmailAddressModalOpen}
          >
            <Title>{__('Edit email')}</Title>
            <Spacer size={1} />
            <IonItem>
              <IonInput
                value={email}
                onIonChange={(event) => this.setState({ email: event.detail.value })}
              ></IonInput>
            </IonItem>
            <Spacer size={1} />
            <IonButton
              color="secondary"
              expand="block"
              onClick={() => {
                this.setState({
                  email: this.state.email,
                  recipientEmailAddressModalOpen: false,
                });
              }}
            >
              {' '}
              {__('Continue')}
            </IonButton>
          </Modal>
        </div>
      </>
    );
  };

  openVoucherContent = (item) => {
    const data = {
      recipient_email: item.recipient_email,
      sender_name: this.props.profile.first_name,
      recipient_name: item.recipient_name,
      points_value: item.points_value,
      personal_message: item.personal_message,
      send_on_date: item.send_on_date,
    };

    this.setState({
      giftVoucherAmount: data.points_value / 100,
      email: data.recipient_email,
      giftVoucherMessage: data.personal_message,
      giftVoucherMesageFromModal: data.personal_message,
      giftVoucherDate: data.send_on_date,
      voucherSummaryPage: true,
      selectedGiftVoucher: item,
    });
    this.props.summaryPageHandler();
  };
  async componentDidMount() {
    const giftVoucherData = this.props.giftVoucherData;
    const giftVouchers = getConfig().general.giftVouchers;
    giftVouchers &&
      this.setState({
        giftVoucherValues: giftVouchers,
        giftVoucherAmount: giftVouchers[0],
      });
    if (giftVoucherData) {
      this.setState({
        giftVoucherAmount: giftVoucherData.points_value / 100,
        email: giftVoucherData.recipient_email,
        giftVoucherMessage: giftVoucherData.personal_message,
        giftVoucherDate: giftVoucherData.send_on_date,
        giftVoucherMesageFromModal: giftVoucherData.personal_message,
        voucherSummaryPage: false,
        sendNewVoucher: true,
        checkoutPage: true,
      });
    }
  }
  componentDidUpdate() {
    if (this.props.giftVoucherSent) {
      this.setState({
        showSuccessSend: true,
      });
      forwardTo('/gift-vouchers');
    }
    if (this.props.giftVoucherRedeemed) {
      this.setState({
        showSuccessResend: true,
      });
      forwardTo('/gift-vouchers');
    }
  }
  vaucherIsScheduled = (date)=>{
    return date - moment.now() > 0 
  }

  render() {
    const { __, sentGiftVouchers } = this.props;
    const { selectedGiftVoucher, sendNewVoucher, showSuccessSend, showSuccessResend } = this.state;
    return (
      <>
        {showSuccessSend || showSuccessResend ? (
          <div className="success-gift-voucher centered">
            <Title className="default-padding centered">{__('Success')}</Title>
            <Spacer size={1} />
            <img src={successIcon}></img>
            <Spacer size={1} />
            {showSuccessSend && (
              <NormalText>
                {__('Your payment was successful and your gift voucher has been sent')}
              </NormalText>
            )}
            {showSuccessResend && (
              <NormalText>{__('Your gift voucher has been resent')}</NormalText>
            )}
            <Spacer size={1} />
            <IonButton
              expand="block"
              color="primary uppercase default-padding"
              onClick={() => {
                this.props.detailsPageHandler();
                this.props.dispatch(getSentGiftVouchers());
                this.resetState(false);
              }}
            >
              {__('Continue')}
            </IonButton>
          </div>
        ) : (
          <>
            {this.props.detailsPage &&
            sentGiftVouchers.length > 0 &&
            sentGiftVouchers.length > 0 &&
            !sendNewVoucher ? (
              <div className="sent-gift-vouchers-wrapper">
                <Title className="default-padding">{__('Recently sent')}</Title>
                <Spacer size={1} />

                <IonList className="gift-voucher-history-list">
                  {sentGiftVouchers.map((voucher) => (
                    <IonItem
                      key={voucher.id}
                      onClick={() => {
                        {voucher.is_paid &&
                          this.openVoucherContent(voucher);

                        }
                      }}
                    >
                      <IonIcon icon={person} />
                      <div className="gift-voucher-user">
                        <StrongText className="ellipsis">{voucher.recipient_email}</StrongText>
                        {voucher.redeemed_on ? (
                          <SmallText className="gift-voucher-date" color="success">
                            {__('Redeemed on')}: {moment(voucher.redeemed_on).format('Do MMM yyyy')}
                          </SmallText>
                        ) : this.vaucherIsScheduled(moment(voucher.send_on_date))? (
                          <SmallText className="gift-voucher-date">
                            {__('Scheduled date')}: {moment(voucher.send_on_date).format('Do MMM yyyy')}
                          </SmallText>
                        ):(
                          <SmallText className="gift-voucher-date">
                            {__('Email sent')}: {moment(voucher.send_on_date).format('Do MMM yyyy')}
                          </SmallText>
                        )
                      }
                      </div>
                      {!voucher.is_paid?
                        <IonButton
                          className='resend-voucher-button'
                          color="primary uppercase"
                          disabled={true}
                        >
                          {__('Processing Payment')}
                        </IonButton>:<>
                      {voucher.redeemed_on ? (
                        <IonButton
                          className='resend-voucher-button'
                          color="primary uppercase"
                          disabled={true}
                        >
                          {__('Redeemed')}
                        </IonButton>
                      ) :this.vaucherIsScheduled(moment(voucher.send_on_date))? (
                        <IonButton className='resend-voucher-button' color="primary uppercase">
                          {__('EDIT')}
                        </IonButton>
                      ): (
                        <IonButton className='resend-voucher-button' color="secondary uppercase">
                          <svg
                            style={{ marginRight: '5px' }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#fff"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5" />
                            <path d="M3 6l9 6l9 -6" />
                            <path d="M15 18h6" />
                            <path d="M18 15l3 3l-3 3" />
                          </svg>
                          {__('Resend')}
                        </IonButton>
                      )}
                      </>}
                    </IonItem>
                  ))}
                </IonList>
                <Spacer size={1} />
                <IonButton
                  color="secondary uppercase default-padding"
                  onClick={() => this.resetState(true)}
                >
                  {__('Send a new gift voucher')}
                </IonButton>
              </div>
            ) : (
              <>
                {selectedGiftVoucher ? (
                  <>
                    {this.drawVoucherOrderSummary(
                      __,
                      selectedGiftVoucher.redeemed_on ? false : true,
                      true,
                    )}
                  </>
                ) : (
                  <>
                    {!this.props.checkoutPage &&
                      !this.props.summaryPage &&
                      this.drawSendVoucherContent(__)}
                    {this.props.summaryPage && this.drawVoucherOrderSummary(__, true, false)}
                    {this.props.checkoutPage && <Checkout giftVoucher={true}></Checkout>}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  }
}
const stateToProps = (state) => {
  const { restaurants } = state.restaurants;
  const { giftVoucherData } = state.orders;
  const { profile } = state.profile;
  return {
    restaurants: restaurants || [],
    profile,
    giftVoucherData,
    auth: state.profile.auth,
  };
};
export default connect(stateToProps)(withTranslation(SendGiftVoucher));
