import React from 'react';
import {
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonLabel,
  IonButton,
  IonAlert,
} from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { Title, SmallText, Spacer, Sectiontitle, NormalText } from '../../components/common';
import { connect } from 'react-redux';
import Basket from '../../lib/basket';
import { forwardTo, insertIntoArray, goBack, isDefined } from '../../lib/utils';
import { Capacitor } from '@capacitor/core';
import { getConfig } from '../../appConfig';
import DrawPayButton from '../../components/drawPayButton';
import CheckoutPay from './CheckoutPay';
import './index.css';
import Modal from '../../components/modal';
import { logout, setCommonModal } from '../../store/actions';
import { checkSnoozedTimes, formatDataForTime } from '../clickAndCollect';
import moment from '../../lib/moment';
import Mobiscroll from '../../components/mobiscroll';

const { SelectOption } = Mobiscroll;
const { getMobile, setMobile } = Basket;
const isWeb = () => Capacitor.getPlatform() === 'web';

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    const defaultCard =
      this.props.cards.length > 0 &&
      this.props.profile.cardToken &&
      this.props.cards.find((card) => card.id === this.props.profile.cardToken);
    this.state = {
      selectedCard: defaultCard,
      defaultCard: defaultCard,
      selectedJudopayCard: null,
      collectedModal: false,
      collectedPay: this.props.cards.length === 0,
      showAllCards: false,
      pickTime: null,
    };
  }

  componentDidMount() {
    const { profile } = this.props;
    const { google } = window;
    if (getConfig().payment === 'judopay') {
      let judoPayCardsLen = profile.judo_pay_cards.length - 1;
      this.changeSelectedPaymentCard(judoPayCardsLen);
    }
    if (profile.cardToken) {
      this.changeSelectedPaymentCard(profile.cardToken);
    }
    window.googlePayClient = new google.payments.api.PaymentsClient({ environment: 'PRODUCTION' });
    if(profile.is_guest && isWeb()){
      forwardTo('/card-add', { addCardFromCheckout: true, title: 'Checkout' });
    }
  }

  componentDidUpdate(prevProps) {
    const { profile } = this.props;
    if (!this.state.collectedPay) {
      let judoPayCardsLen = profile.judo_pay_cards.length - 1;
      if (prevProps.profile.cardToken !== profile.cardToken) {
        this.changeSelectedPaymentCard(profile.cardToken);
      }
      if (
        getConfig().payment === 'judopay' &&
        prevProps.profile.judo_pay_cards.length !== profile.judo_pay_cards.length
      ) {
        this.changeSelectedPaymentCard(judoPayCardsLen);
      }
    }
  }

  changeSelectedPaymentCard = (cardId) => {
    if (cardId !== 'collectedPay') {
      const selectedCard = this.props.cards.find((card) => card.id === cardId);
      this.setState({ selectedCard, defaultCard: selectedCard, collectedPay: false }, () => {
        Basket.changeSelectedCard(cardId);
      });
    } else {
      this.setState({ collectedPay: true, selectedCard: null }, () => { });
    }
  };

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipContactDetails
    ) {
      forwardTo('/order', { skipBackToThePreviousPage: true });
    } else if (this.props.location.pathname === '/checkout') {
      forwardTo('/order', { skipBackToThePreviousPage: true });
    } else {
      goBack();
    }
  };

  showCollectedModal(modalOpen) {
    this.setState({ collectedModal: modalOpen });
  }
  changeTime = (selectedTime, minDT) => {
    let h = parseInt(selectedTime.split(':')[0]);
    let m = parseInt(selectedTime.split(':')[1]);
    const formattedDT = moment(minDT).hours(h).minutes(m);
    Basket.setCollectionTime(formattedDT);
  };

  setPickTime = (inst, minDT) => {
    if (inst.getVal()) {
      this.changeTime(inst.getVal(), minDT);
      this.setState({ pickTime: inst.getVal() });
    }
  };
  render() {
    const {
      __,
      cards,
      clientSecret,
      dispatch,
      location,
      profile,
      deliveryTimeModalOpen,
      restaurants,
    } = this.props;

    const { collectedModal, showAllCards, defaultCard, collectedPay, pickTime } = this.state;
    const basketInstance = this.props.basketInstance || Basket;
    const orderType = basketInstance.getOrderType();
    let timePickerOptions = [];
    let snoozedTimes = [];
    let minDT = moment();
    if (Basket.getRestaurant()) {
      const store = restaurants.find((restaurant) => restaurant.id === Basket.getRestaurant().id);
      if (Basket.getDeliveryOption().id !== 'table')
        snoozedTimes = checkSnoozedTimes(store, Basket.getDeliveryOption().id);
      if (store && isDefined(store.order_slot_lead_time)) {
        minDT.add(store.order_slot_lead_time, 'minutes');
      }
      timePickerOptions = formatDataForTime(
        Basket.getRestaurant(),
        minDT,
        Basket.getRestaurant().id,
        Basket.getDeliveryOption().id === 'charter-delivery' ? true : false,
        false,
        snoozedTimes,
      );
    }
    return (
      <>
        <Loading transparent>
          <Layout
            hideSecondToolbar={true}
            color="transparent"
            headerTitle={__('Checkout')}
            backHandler={this.backHandler}
          >
            {isWeb() ? (
              <>
                {!showAllCards ? (
                  <div className="flex-row-wrapper absolute-content">
                    <Title className="no-margin-bottom">{__('Checkout')}</Title>
                    <NormalText color="primary" className=" thiner-text">
                      {__('Confirm the payment method to use to complete')}
                    </NormalText>
                    <Spacer size={1} />
                    <div className="scrollable-y">
                      <IonList lines="full">
                        <IonRadioGroup
                          className="ion-radio-checkout"
                          onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)}
                          value={this.state.selectedCard ? this.state.selectedCard.id : 'collectedPay'}
                        >
                          {cards.length > 0 ? (
                            <>
                              <IonItem key={defaultCard.id}>
                                <div tabIndex="-1"></div>
                                <IonLabel className="ion-text-wrap">
                                  <NormalText color="primary" className="single-item bold">
                                    {defaultCard.name || ''}
                                  </NormalText>
                                  <Sectiontitle className="no-margin">
                                    •••• •••• •••• {defaultCard.last4}
                                  </Sectiontitle>
                                </IonLabel>
                                <IonRadio slot="start" color="tertiary" value={defaultCard.id + ''} />
                              </IonItem>
                              <SmallText color="primary">
                                <IonButton
                                  expand="block"
                                  fill="clear"
                                  color="secondary"
                                  className="link underlined"
                                  onClick={() =>
                                    this.setState({ showAllCards: true, selectedCard: defaultCard })
                                  }
                                >
                                  <div className="secondary-color link-lefted">
                                    {__('Select a different card')}
                                  </div>
                                </IonButton>
                              </SmallText>
                            </>
                          ) : (
                            <>
                              <Spacer />
                              <IonItem lines="none">
                                <div tabIndex="-1"></div>
                                <IonLabel className="ion-text-wrap">
                                  <SmallText color="primary">
                                    <IonButton
                                      expand="block"
                                      fill="clear"
                                      color="secondary"
                                      className="link underlined"
                                      onClick={() =>
                                        forwardTo('/card-add', { addCardFromCheckout: true })
                                      }
                                    >
                                      <div className="link-lefted secondary-color">
                                        {__('Add a payment card')}
                                      </div>
                                    </IonButton>
                                  </SmallText>
                                </IonLabel>
                                <IonRadio
                                  slot="start"
                                  color="secondary"
                                  onClick={() => forwardTo('/card-add', { addCardFromCheckout: true })}
                                />
                              </IonItem>
                            </>
                          )}
                          {orderType === 'Click & Collect' && getConfig().appType.hasCollectedPay && (
                            <IonItem key="collectedPay">
                              <div tabIndex="-1"></div>
                              <IonLabel className="ion-text-wrap">
                                <NormalText color="primary" className="single-item bold">
                                  {__('Pay on Collection')}
                                </NormalText>
                              </IonLabel>
                              <IonRadio slot="start" color="primary" value="collectedPay" />
                            </IonItem>
                          )}
                        </IonRadioGroup>
                      </IonList>
                    </div>
                    <div className="flex-min">
                      <IonButton
                        color="secondary"
                        disabled={cards.length === 0}
                        onClick={() => {
                          if (collectedPay) {
                            this.showCollectedModal(true);
                          } else {
                            Basket.createOrder();
                          }
                        }}
                        expand="block"
                      >
                        {collectedPay ? __('Place Order') : __('Pay ') + Basket._getTotal()}
                      </IonButton>
                      <Spacer size={1} />
                      {isWeb() ? (
                        <CheckoutPay
                          clientSecret={clientSecret}
                          dispatch={dispatch}
                          __={__}
                          location={location}
                          profile={profile}
                        />
                      ) : (
                        <DrawPayButton __={__} googlePayClient={window.googlePayClient} />
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <Title className="no-margin-bottom">{__('Select payment card')}</Title>
                    <NormalText color="primary" className=" thiner-text">
                      {__('Confirm the payment method to use to complete')}
                    </NormalText>
                    <Spacer size={1} />
                    <div className="scrollable-y">
                      <IonList lines="full">
                        {getConfig().payment === 'judopay' ? (
                          <IonRadioGroup
                            onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)}
                            value={this.state.selectedCard.id}
                          >
                            {cards.map((card, id) => {
                              const { cardLastFour, cardExpiry, cardType, name } = card;
                              let cardEx = insertIntoArray(cardExpiry, 2, '/');
                              return (
                                <IonItem key={id}>
                                  <div tabIndex="-1"></div>
                                  <IonLabel className="ion-text-wrap">
                                    <NormalText color="primary" className="single-item bold">
                                      {name || ''}
                                    </NormalText>
                                    <Sectiontitle className="no-margin">
                                      •••• •••• •••• {cardLastFour}
                                    </Sectiontitle>
                                    <SmallText color="primary">
                                      {__(cardType)} - {cardEx}
                                    </SmallText>
                                  </IonLabel>
                                  <IonRadio
                                    slot="start"
                                    color="tertiary"
                                    value={id + ''}
                                    onIonSelect={() => {
                                      this.setState({ selectedCard: card }, () => {
                                        Basket.changeSelectedCard(id);
                                      });
                                    }}
                                  />
                                </IonItem>
                              );
                            })}
                          </IonRadioGroup>
                        ) : (
                          <IonRadioGroup
                            onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)}
                            value={this.state.selectedCard.id}
                          >
                            {cards.map((card) => {
                              const { id, last4, brand, exp_month, exp_year, name } = card;
                              return (
                                <IonItem key={id}>
                                  <div tabIndex="-1"></div>
                                  <IonLabel className="ion-text-wrap">
                                    <NormalText color="primary" className="single-item bold">
                                      {name}
                                    </NormalText>
                                    <Sectiontitle className="no-margin">
                                      •••• •••• •••• {last4}
                                    </Sectiontitle>
                                    <SmallText color="primary">
                                      {__(brand)} - {exp_month}/{exp_year}
                                    </SmallText>
                                  </IonLabel>
                                  <IonRadio
                                    slot="start"
                                    value={id}
                                    color="tertiary"
                                    onIonSelect={() => {
                                      this.setState({ selectedCard: card, collectedPay: false }, () => {
                                        Basket.changeSelectedCard(id);
                                      });
                                    }}
                                  />
                                  {defaultCard.id === id && (
                                    <SmallText className="secondary-color">{__('DEFAULT')}</SmallText>
                                  )}
                                </IonItem>
                              );
                            })}
                          </IonRadioGroup>
                        )}
                      </IonList>
                      <IonButton
                        fill="clear"
                        color="secondary"
                        className="link underlined"
                        onClick={() => forwardTo('/card-add', { addCardFromCheckout: true })}
                      >
                        {__('Add New Payment Card')}
                      </IonButton>
                    </div>
                    <div className="flex-min">
                      <IonButton
                        color="secondary"
                        disabled={cards.length === 0}
                        onClick={() => this.setState({ showAllCards: false })}
                        expand="block"
                      >
                        {__('Continue')}
                      </IonButton>
                      {getConfig()?.prebuildPayment?.isVisible ? (
                        <IonButton
                          color="secondary"
                          onClick={() => {
                            Basket.createStripeOrder(getConfig().prebuildPayment?.label);
                          }}
                          expand="block"
                        >
                          {__(getConfig().prebuildPayment?.label)}
                        </IonButton>
										) : null}
                    </div>
                  </div>
                )}
              </>
            ) : null}
            {!isWeb() ? (
              <>
                {getConfig()?.prebuildPayment?.showOnNative ? (
                  <IonButton
                    color="secondary"
                    expand="block"
                    onClick={() => {
                      Basket.createStripeOrder(getConfig().prebuildPayment?.label);
                    }}
                    
                  >
                    {__(getConfig().prebuildPayment?.label)}
                  </IonButton>
                ) : null}
                {!isWeb() && getConfig().nativePayment?.isVisible ? (
                  <IonButton
                    color="secondary"
                    expand="block"
                    onClick={() => {
                      Basket.createOrder('NativePayment');
                    }}
                  >
                    {__(getConfig().nativePayment?.label)}
                  </IonButton>
                ) : null}
              </>) : null
            }
            {this.props.profile.is_guest && (
              <IonButton
                expand="block"
                fill="clear"
                color="secondary"
                onClick={() => {
                  this.props.dispatch(logout());
                }}
              >
                {__('Cancel')}
              </IonButton>
            )}
          </Layout>
          <IonAlert
            isOpen={collectedModal}
            onDidDismiss={() => this.showCollectedModal(false)}
            header={__('Pay on Collection')}
            message={__(
              'You agree to pay the amount shown before collecting your order. Earned loyalty will be updated after payment',
            )}
            buttons={[
              {
                text: __('Cancel'),
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => this.showCollectedModal(false),
              },
              {
                text: __('Confirm'),
                handler: () => {
                  Basket.createOrder('collectedPay');
                  this.showCollectedModal(false);
                },
              },
            ]}
          />
        </Loading>
        <Modal
          className={'delivery-time-modal'}
          isOpen={deliveryTimeModalOpen}
          onDidDismiss={() => dispatch(setCommonModal('deliveryTimeModalOpen', false))}
        >
          <Title>{__('Select Collection Time')}</Title>
          <NormalText>{__('Please select different time')}</NormalText>
          <Spacer />
          <SelectOption
            display="inline"
            onSet={(e, inst) => this.setPickTime(inst, minDT)}
            data={timePickerOptions}
            label="Location"
            value={pickTime}
            inputStyle="box"
            placeholder={__('Select Collection Time')}
            setText={__('OK')}
            cancelText={__('Cancel')}
            onInit={() => {
              if (timePickerOptions.length > 0) {
                const firstAvailableTime = timePickerOptions.find((i) => !i.disabled);
                if (!pickTime && firstAvailableTime && pickTime !== firstAvailableTime.value) {
                  this.changeTime(firstAvailableTime.value, minDT);
                }
              }
            }}
          />
          <Spacer />
          <IonButton
            expand="block"
            color="primary"
            className="uppercase okx-font-secondary"
            onClick={() => dispatch(setCommonModal('deliveryTimeModalOpen', false))}
          >
            {__('Continue')}
          </IonButton>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    cards: store.orders.cards || [],
    clientSecret: store.orders.clientSecret,
    profile: store.profile.profile || {},
    deliveryTimeModalOpen: store.common.deliveryTimeModalOpen,
    restaurants: store.restaurants.restaurants || [],
  };
};

export default connect(mapStateToProps)(withTranslation(Checkout));
