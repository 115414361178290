import React from 'react';
import moment from '../../lib/moment';
import { IonItem, IonRow, IonCol, IonGrid, IonButton, IonAlert } from '@ionic/react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { Title, NormalText, Spacer, StrongText, Subtitle } from '../../components/common';
import Box, { BoxHeader } from '../../components/box';
import { connect } from 'react-redux';
import { createNewBasketInstance } from '../../lib/basket';
import { forwardTo, visaEndingDrawText } from '../../lib/utils';
import OrderContent from '../../components/orderContent';
import Basket from '../../lib/basket';
import AmountPaid from '../../components/amountPaid';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import { checkCancelOrder, setOrdersProp } from '../../store/orders/actions';
import './index.css';
import { logout } from '../../store/actions';

class OrderCompleted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCancel: false,
      orderId: null,
      restaurantId: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.lastOrder) {
      if (prevProps.lastOrder === null || prevProps.lastOrder.id !== this.props.lastOrder.id) {
        const { lastOrder } = this.props;
        const orderArr = lastOrder;
        const now = moment();
        let cutoffTime = orderArr ? orderArr.cutoff_time : now;
        let cutoffTimeMoment = new moment(cutoffTime, 'YYYY-MM-DD HH:mm:ss');
        const utcOffset = cutoffTimeMoment.utcOffset();
        cutoffTimeMoment = cutoffTimeMoment.add('minutes', utcOffset);
        if (now.isBefore(cutoffTimeMoment)) {
          this.setState({
            isCancel: true,
            orderId: orderArr.id,
            restaurantId: orderArr.restaurant_id,
          });
        }
      }
    }
  }

  drawContentTitle = (__, deliveryOption, id, orderType) => {
    if (orderType === 'Outpost Drop-Off') {
      return (
        <Title>
          {__('Drop Point')} {__('Order')} #{id}
        </Title>
      );
    } else if (orderType === 'Delivery') {
      return (
        <Title>
          <strong>
            {__('Delivery')} {__('Order')} #{id}
          </strong>
        </Title>
      );
    } else if (orderType === 'Table') {
      return (
        <Title>
          <strong>
            {__('At Table')} {__('Order')} #{id}
          </strong>
        </Title>
      );
    } else {
      return (
        <Title>
          { __('Click And Collect') } {__('Order')} #{id}
        </Title>
      );
    }
  };

  drawContent = (__, order, orderType, basketInstance, orderCompletePage) => {
    let paymentMetod = basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage);
    const isOldLayoutOfConfirmationScreens = getConfig().appType.oldLayoutConfirmationScreens;
    if (orderType === 'Click & Collect' || orderType === 'table') {
      return (
        <>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <NormalText tag="strong" className="bold mr-5">
                {__('Collect from')}:
              </NormalText>
            </IonCol>
            <IonCol className="righted">
              <NormalText color="primary" className="thiner-text">
                {order.restaurant_name}
              </NormalText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <NormalText tag="strong" className="bold mr-5">
                {__('Collect at')}:
              </NormalText>
            </IonCol>
            <IonCol className="righted">
              <NormalText color="primary" className="thiner-text">
                {order.is_asap ? __('ASAP') : basketInstance.formatOrderTime(true, 'DD MMM YYYY [at] h:mm a')}
              </NormalText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <NormalText tag="strong" className="bold mr-5">
                {__('Order placed on')}:
              </NormalText>
            </IonCol>
            <IonCol className="righted">
              <NormalText color="primary" className="thiner-text">
                {moment(order.created_at).format(
                  /* 'ddd DD MMMM YYYY [at] LT' */ 'DD MMM YYYY [at] h:mm a',
                )}
              </NormalText>
            </IonCol>
          </IonRow>
          {!isOldLayoutOfConfirmationScreens ? (
            <IonRow>
              <IonCol size="5" className="paddLR">
                <NormalText tag="strong" className="bold mr-5">
                  {__('Payment Method')}:
                </NormalText>
              </IonCol>
              <IonCol className="righted">
                <NormalText color="primary" className="thiner-text">
                  {visaEndingDrawText(paymentMetod)}
                </NormalText>
              </IonCol>
            </IonRow>
          ) : null}
        </>
      );
    } else if (orderType === 'Delivery') {
      return (
        <>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <NormalText tag="strong" className="bold">
                {__('Delivery from')}:
              </NormalText>
            </IonCol>
            <IonCol className="righted">
              <NormalText color="primary" className="thiner-text">
                {order.delivery_address.addressLine1}, {order.delivery_address.place},{' '}
                {order.delivery_address.postalCode}
              </NormalText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <NormalText tag="strong" className="bold">
                {__('Order placed on')}:
              </NormalText>
            </IonCol>
            <IonCol className="righted">
              <NormalText color="primary" className="thiner-text">
                {moment(order.created_at).format('D MMM YYYY [at] h:mm a')}
              </NormalText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR">
              <NormalText tag="strong" className="bold">
                {__('Delivery at')}:
              </NormalText>
            </IonCol>
            <IonCol className="righted">
              <NormalText color="primary" className="thiner-text">
                {basketInstance.formatOrderTime(true)}
              </NormalText>
            </IonCol>
          </IonRow>
          {
            /*order && order.order_value > 0 ||*/ orderType !== 'Click & Collect' ? (
              <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
                <IonCol size="5" className="paddLR">
                  <NormalText tag="strong" className="bold">
                    {__('Payment Method')}:
                  </NormalText>
                </IonCol>
                <IonCol className="righted">
                  <NormalText color="primary" className="thiner-text">
                    {visaEndingDrawText(paymentMetod)}
                  </NormalText>
                </IonCol>
              </IonRow>
            ) : null
          }
        </>
      );
    } else if (orderType === 'Outpost Drop-Off') {
      return (
        <>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <NormalText tag="strong" className="bold">
                {__('Drop-off at')}:
              </NormalText>
            </IonCol>
            <IonCol className="righted">
              <NormalText color="primary" className="thiner-text">
                {order.restaurant_name}
              </NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <NormalText tag="strong" className="bold">
                {__('Order Placed')}:
              </NormalText>
            </IonCol>
            <IonCol className="righted">
              <NormalText color="primary" className="thiner-text">
                {moment(order.created_at).format('ddd DD MMMM YYYY [at] LT')}
              </NormalText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="5" className="paddLR">
              <NormalText tag="strong" className="bold">
                {__('Drop-off Time')}:
              </NormalText>
            </IonCol>
            <IonCol className="righted">
              <NormalText color="primary" className="thiner-text">
                {basketInstance.formatOrderTime(true)}
              </NormalText>
            </IonCol>
          </IonRow>
          {order && order.order_value > 0 ? (
            <IonRow>
              <IonCol size="5" className="paddLR">
                <NormalText tag="strong" className="bold">
                  {__('Payment Method')}:
                </NormalText>
              </IonCol>
              <IonCol className="righted">
                <NormalText color="primary" className="thiner-text">
                  {visaEndingDrawText(paymentMetod)}
                </NormalText>
              </IonCol>
            </IonRow>
          ) : null}
        </>
      );
    } else if (orderType === 'Table') {
      return (
        <>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR mr-5">
              <NormalText tag="strong">{__('Ordered at')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>{order.restaurant_name}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'block' : ''}`}>
            <IonCol size="5" className="paddLR mr-5">
              <NormalText tag="strong">{__('Table number')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>{order.table_name}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR mr-5">
              <NormalText tag="strong">{__('Order Placed')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>{moment(order.created_at).format('D MMM YYYY [at] h:mm a')}</NormalText>
            </IonCol>
          </IonRow>
          <IonRow className={`${isOldLayoutOfConfirmationScreens ? 'inline-block' : ''}`}>
            <IonCol size="5" className="paddLR mr-5">
              <NormalText tag="strong">{__('Payment Method')}:</NormalText>
            </IonCol>
            <IonCol className="righted self-aligned">
              <NormalText>{visaEndingDrawText(paymentMetod)}</NormalText>
            </IonCol>
          </IonRow>
        </>
      );
    }
  };
  drawOrder = (order, orderCompletePage) => {
    const { __, cards, lastOrder } = this.props;
    const { id } = order;
    const basketInstance = createNewBasketInstance();
    basketInstance.recreateOrder(order);
    const orderType = basketInstance.getOrderType();
    const orderTypeTitle =
      basketInstance.getOrderType() === 'Click & Collect'
        ? 'Collection'
        : basketInstance.getOrderType();
    const hasCancelOrder = getConfig().appType.hasCancelOrder;
    let paymentMetod = basketInstance.formatPaymentMethod(this.props.cards, __, orderCompletePage);
    const isOldLayoutOfConfirmationScreens = getConfig().appType.oldLayoutConfirmationScreens;
    return (
      <>
        {!isOldLayoutOfConfirmationScreens ? (
          <>
            <div className="order-status">
              {lastOrder && lastOrder.status ? __(lastOrder.status) : ''}
            </div>
            <Spacer size={1} />

            {this.drawContentTitle(__, Basket.getDeliveryOption(), id, orderTypeTitle)}
            <Spacer size={1} />
            {/* <Box> */}
            {/* <BoxHeader> */}
            <IonGrid className="okx-box-header-grid">
            <Subtitle>{__('Order Details')}</Subtitle>

              {this.drawContent(__, order, orderType, basketInstance, orderCompletePage)}
            </IonGrid>
            {/* </BoxHeader> */}
            <Spacer size={3} />
            <StrongText className='roboto-slab big-text'>{__('Items Ordered')}</StrongText>
            <OrderContent basketInstance={basketInstance} type="orderHistory" />
            <AmountPaid order={order} cards={cards} />
            {/* </Box> */}
            {orderType !== 'Click & Collect' ? (
              this.state.isCancel &&
                ['PAID', 'PARSED', 'NEW', 'new', 'paid', 'parsed'].indexOf(order.status) !== -1 &&
                hasCancelOrder ? (
                <IonButton
                  expand="block"
                  fill="clear"
                  className="link underlined"
                  color="tertiary"
                  onClick={() => this.handleCancelOrderModal(true)}
                >
                  {__('Cancel this order')}
                </IonButton>
              ) : null
            ) : null}
                    <Spacer size={1}/>

                  {this.props.profile.is_guest && (
          <IonButton
            className="uppercase"
            expand="block"
            color='secondary'
            onClick={() => {
              this.props.dispatch(logout());
            }}
          >
            {__('Done')}
          </IonButton>
        )}
          </>
        ) : (
          <>
            <div className="order-status">
              {lastOrder && lastOrder.status ? __(lastOrder.status) : ''}
            </div>
            {this.drawContentTitle(__, Basket.getDeliveryOption(), id, orderTypeTitle)}
            {isOldLayoutOfConfirmationScreens ? (
              <div className="order-status-payment-method">
                <IonCol size="5" className="mr-10">
                  <StrongText tag="strong" >
                    {__('Payment Method')}:
                  </StrongText>
                </IonCol>
                <IonCol>
                  <NormalText color="primary" className="thiner-text">
                    {visaEndingDrawText(paymentMetod)}
                  </NormalText>
                </IonCol>
              </div>
            ) : null}
            <Spacer size={1} />
            <Box>
              <BoxHeader>
                {/* <p className="light-text">
										<NormalText tag="strong">{ __('Order Location') }:</NormalText> <NormalText>{ order.restaurant_name }</NormalText><br />
									</p>
									<p className="light-text">
										<NormalText tag="strong">{ __('Order Time') }:</NormalText> <NormalText>{ basketInstance.formatOrderTime() }</NormalText><br />
									</p> */}
                {this.drawContent(__, order, orderType, basketInstance, orderCompletePage)}
              </BoxHeader>
              <Spacer size={2} />
              <StrongText className="bold">{__('Items Ordered')}</StrongText>
              <OrderContent basketInstance={basketInstance} type="orderHistory" />
              <AmountPaid order={order} cards={cards} />
            </Box>
          </>
        )}
      </>
    );
  };

  noOrder = () => (
    <IonItem lines="none">
      <div tabIndex="-1" className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>
        {this.props.__('No order')}
      </div>
    </IonItem>
  );

  backHandler = () => {
    const { location } = this.props;
    if (location && location.state && location.state.completedOrder) {
      forwardTo('/dashboard');
      forwardTo('/click-and-collect');
      Basket.reset();
    }
  };

  cancelOrder = () => {
    const { orderId, isCancel, restaurantId } = this.state;
    if (isCancel) {
      this.props.dispatch(checkCancelOrder(orderId, restaurantId));
      this.setState({ orderId: null, isCancel: false, restaurantId: null });
    }
  };

  handleCancelOrderModal = (flag) => {
    this.props.dispatch(setOrdersProp('cancelOrderModal', flag));
  };

  render() {
    const { __, lastOrder } = this.props;
    const order = this.props.order || lastOrder;
    const orderCompletePage = true;
    return (
      <Loading>
        <Layout hideSecondToolbar={true} color="transparent" headerTitle={__('Order Completed')} backHandler={this.backHandler}>
          <div className="absolute-content order-details">
            <div className="scrollable-y">
              {order ? this.drawOrder(order, orderCompletePage) : this.noOrder()}
            </div>
          </div>
        </Layout>
        <IonAlert
          isOpen={this.props.cancelOrderModal === true}
          onDidDismiss={() => this.handleCancelOrderModal(false)}
          header={__('Confirm')}
          message={__('Do you want to cancel this order?')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.cancelOrder(),
            },
          ]}
        />
      </Loading>
    );
  }
}

const mapStateToProps = (store) => {
  const orders = store.orders;
  const { cancelOrderModal, latestOrderEvent } = orders;
  const { restaurants } = store.restaurants;
  let lastOrder = null;
  const orderHistory = orders.orderHistory;
  if (orderHistory && orderHistory.length > 0) {
    lastOrder = orderHistory[0];
  }
  const { profile } = store.profile;

  return {
    lastOrder,
    cards: orders.cards || [],
    restaurants: restaurants || [],
    cancelOrderModal,
    latestOrderEvent,
    profile
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation(OrderCompleted)));
