import React from 'react';
import { withRouter } from 'react-router';
import { IonButton, IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { SmallText, Spacer, StrongText, Subtitle, Title } from '../../components/common';
import Basket from '../../lib/basket';
import ValidateButton from '../../components/validateButton';
import { getConfig } from '../../appConfig';
import Modal from '../../components/modal';
import { ApplyPoints } from '../../screens/applyPoints';
import Incrementer from '../../components/incrementer';
import { connect } from 'react-redux';
import OrderContent from '../../components/orderContent';
import { forwardTo, validateProfileData, goBack, go, isWebConfig } from '../../lib/utils';
import { setProtectedReferrer, restoreAuth } from '../../store/actions';
import ContentHeader from '../../components/contentHeader';
import {
  setOrderTypeMembership,
  setOrderTypeRegular,
  setOrderSubscriptionFlow,
  setSelectedSubscription,
  getSubscriptions,
} from '../../store/actions';
import { ApplyAllergens } from '../allergens';
import { pencilOutline } from 'ionicons/icons';
import Mobiscroll from '../../components/mobiscroll';
import { Capacitor } from '@capacitor/core';
import './index.css';

const isWeb = () => Capacitor.getPlatform() === 'web';
const { SelectOption } = Mobiscroll;
const {
  changeItemQuantity,
  itemsCount,
  setMobile,
  getMobile,
  getAllergen,
  getTotal,
  isMinimumOrderTotalSatisfied,
  getPickUpPoint,
  getDeliveryAddress,
  getDeliveryOption,
} = Basket;

class OrderSummaryRaw extends React.Component {
  constructor(props) {
    super(props);
    if (!getMobile() && this.props.profile && this.props.profile.mobile) {
      setMobile(this.props.profile.mobile);
    }
    this.state = {
      quantityModal: null,
      allergenModalOpen: false,
      applyPointsModalOpen: false,
      mobile: getMobile() || '',
      applyLoyaltyModalOpen: false,
      tableNumberModalOpen: false,
      tableNumberValue: Basket.getTableNumber(),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.profile.mobile !== prevProps.profile.mobile) {
      this.setState({ mobile: this.props.profile.mobile });
    }
  }

  handleInput = (key, val) => {
    this.setState({ [key]: val });
    setMobile(val);
  };
  tableNumberChanged = () => {
    if (this.state.tableNumberValue !== '') {
      Basket.setTableNumber(this.state.tableNumberValue);
    }
    this.setState({ tableNumberModalOpen: false });
  };
  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromItemDetails
    ) {
      // skip item details page when we going back from order summary
      go(-2);
    } else {
      goBack();
    }
  };
  componentDidMount() {
    const { selectedSubscription, subscriptions, profile } = this.props;
    const basketInstance = this.props.basketInstance || Basket;
    const tableNumbers = Basket.getRestaurant()?.table_plan_json;
    this.setState({
      totalTableNumbers: tableNumbers,
    });
    if (selectedSubscription?.idx >= 0 && profile.active_subscription.id) {
      const active_subscription = subscriptions[selectedSubscription?.idx];
      if (active_subscription && active_subscription.id && this.props.isCollection) {
        Basket.setDiscount(active_subscription, 'AS');
      }
    } else if (profile.active_subscription && profile.active_subscription.id) {
      const index = subscriptions.findIndex((e) => e.id === profile.active_subscription.id);
      this.props.setSelectedSubscription({ id: profile.active_subscription.id, idx: index });
      basketInstance.setSubscription(profile.active_subscription);
    }
  }
  handleSubmit = () => {
    const { cards } = this.props;
    if (
      this.state.totalTableNumbers.filter((el) => el.value == Basket.getTableNumber()).length ===
        0 &&
      Basket.getOrderType() == 'Table'
    ) {
      this.setState({ tableNumberModalOpen: true });
      return;
    }

    if (!isMinimumOrderTotalSatisfied()) {
      // display toast with flag 'true'
      isMinimumOrderTotalSatisfied(true);
      return;
    }
    const uuid = `${this.props.profile.id}_${Date.now()}`;
    Basket.setUUID(uuid);
    if (parseFloat(getTotal()) === 0) {
      Basket.createOrder();
      return;
    }
    if (cards && cards.length >= 1) {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        forwardTo('/checkout');
        setMobile(this.props.profile.mobile);
      } else {
        forwardTo('/contact-details');
      }
    } else {
      if (this.props.profile.mobile && this.props.profile.first_name) {
        setMobile(this.props.profile.mobile);
        forwardTo('/checkout');
      } else {
        forwardTo('/contact-details');
      }
    }
  };

  handleNativeSubmit = () => {
    if (
      this.state.totalTableNumbers.filter((el) => el.value == Basket.getTableNumber()).length ===
        0 &&
      Basket.getOrderType() == 'Table'
    ) {
      this.setState({ tableNumberModalOpen: true });
      return;
    }
    if (!isMinimumOrderTotalSatisfied()) {
      isMinimumOrderTotalSatisfied(true);
      return;
    }
    const uuid = `${this.props.profile.id}_${Date.now()}`;
    Basket.setUUID(uuid);
    if (parseFloat(getTotal()) === 0) {
      Basket.createOrder();
      return;
    }
    if (this.props.profile.mobile && this.props.profile.first_name) {
      setMobile(this.props.profile.mobile);
      Basket.createOrder('NativePayment');
    } else {
      forwardTo('/contact-details');
    }
  };

  handleOrderItemClick = (item, index) => {
    this.setState({ quantityModal: { item, index } });
  };

  updateItemQuantity = () => {
    const { item, index } = this.state.quantityModal;
    if (this.state.quantityModal) {
      changeItemQuantity(index, item.quantity);
      this.setState({ quantityModal: null });
    }
  };

  onIncrementerUpdate = (newQuantity) => {
    this.setState({
      quantityModal: {
        ...this.state.quantityModal,
        item: {
          ...this.state.quantityModal,
          quantity: newQuantity,
        },
      },
    });
  };
  handleApplyLoyaltyModal = (flag) => {
    const { history, auth } = this.props;
    const isAuth = auth.loggedIn;
    if (!isAuth) {
      this.props.dispatch(setProtectedReferrer(history.location.path));
      this.props.dispatch(restoreAuth());
      forwardTo('/login');
    } else {
      forwardTo('/apply-loyalty');
    }
  };

  handleApplyModal = (flag) => {
    const { history, auth } = this.props;
    const isAuth = auth.loggedIn;
    if (!isAuth) {
      this.props.dispatch(setProtectedReferrer(history.location.path));
      this.props.dispatch(restoreAuth());
      forwardTo('/login');
    } else {
      if (isWebConfig()) {
        this.setState({ applyPointsModalOpen: flag });
      } else {
        forwardTo('/apply-points');
      }
    }
  };
  handleAllergensModal = (flag) => {
    if (isWebConfig()) {
      this.setState({ allergenModalOpen: flag });
    } else {
      forwardTo('/allergens');
    }
  };
  tableNumberHandler = () => {
    this.setState({ tableNumberModalOpen: true });
  };
  formatTableNumberForSelect = (selectedRestaurantId) => {
    const { __ } = this.props;
    let arrForSelect = [];
    if (selectedRestaurantId) {
      (this.state.totalTableNumbers || []).map((table, index) => {
        arrForSelect.push({ text: table.name, value: table.value });
        return null;
      });
    }
    return [{ text: __('Select table'), value: null }, ...arrForSelect];
  };
  selectTableNumber = (event, data) => {
    const tableId = data.getVal();
    this.setState({
      tableNumberValue: tableId,
    });
  };
  render() {
    const { __, profile, auth } = this.props;
    const { quantityModal, allergenModalOpen, applyPointsModalOpen, mobile } = this.state;
    const valid = validateProfileData(profile).isValid;
    const allergens = getAllergen() || [];
    const isAuth = auth.loggedIn;
    const labelTitle =
      Basket.getOrderType() === __('Click & Collect')
        ? __('Click & Collect Order')
        : Basket.getOrderType();
    const selectedResturant = Basket.getRestaurant()?.table_plan_json;
    return (
      <>
        <div
          className={`absolute-content flex-row-wrapper ${!isWebConfig() && 'remove-top-padding'}`}
        >
          <div className="scrollable-y checkout">
            {!isWebConfig() && <Title className="no-margin-bottom">{labelTitle}</Title>}
            {Basket.getItems().length > 0 ? (
              <ContentHeader
                __={__}
                deliveryOption={getDeliveryOption() || {}}
                orderType={labelTitle}
                deliveryAddress={getDeliveryAddress()}
                pickUpPoint={getPickUpPoint()}
                tableNumberHandler={this.tableNumberHandler}
              />
            ) : null}
            <IonItem className="contact-number" lines="full">
              <div tabIndex="-1"></div>
              <IonLabel slot="start" className="ion-text-wrap contact-number-label">
                <StrongText className="no-margin">{__('Contact number')}</StrongText>
              </IonLabel>
              <IonInput
                className="mobile-field"
                onIonChange={(e) => this.handleInput('mobile', e.target.value)}
                clearInput
                required={true}
                type="tel"
                pattern="tel"
                inputmode="tel"
                value={mobile}
                maxlength={15}
              >
                {mobile === '' ? (
                  // <IonButtons slot="end" className="no-margin contact-number-btns">
                  // 	<IonButton color="gray" /*disabled={ value === '' }*/ fill="clear" size="small" /*onTouchStart={() => this.togglePass(true)} onTouchEnd={() => this.togglePass(false)} onMouseDown={() => this.togglePass(true)} onMouseUp={() => this.togglePass(false)}*/>
                  // 	</IonButton>
                  // </IonButtons>
                  <IonIcon icon={pencilOutline} className="contact-number-icon" />
                ) : null}
              </IonInput>
            </IonItem>
            <Spacer />
            <StrongText className="roboto-slab big-text">{__('Items Ordered')}</StrongText>
            {itemsCount() > 0 ? (
              <OrderContent
                showAddItems={true}
                handleOrderItemClick={this.handleOrderItemClick.bind(this)}
                handleApplyModal={this.handleApplyModal}
              />
            ) : (
              <IonItem lines="none">
                <div
                  tabIndex="-1"
                  className="sectiontitle"
                  style={{ width: '100vh', textAlign: 'center' }}
                >
                  {__('No items')}
                </div>
              </IonItem>
            )}
            <div className="validate-content">
              <ValidateButton />
              {isAuth && !valid && getConfig().appType.hasEmailValidationEnabled ? (
                <div className="verified-content">
                  <SmallText color="grey">
                    {__('You can earn, but not redeem points until your account is verified')}
                  </SmallText>
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex-min">
            {allergens.length > 0 ? (
              <IonButton
                fill="clear"
                expand="block"
                className="link underlined"
                color="primary"
                onClick={() => this.handleAllergensModal(true)}
              >
                {__('View allergen information')}
              </IonButton>
            ) : null}
            {!auth.loggedIn ? (
              <IonButton
                style={{ fontSize: '13px' }}
                className="uppercase login-btn"
                expand="block"
                color="primary"
                onClick={() => forwardTo('/login', { backToOrder: true })}
              >
                {__('Already registered? Login')}
              </IonButton>
            ) : (
              <IonButton
                disabled={itemsCount() === 0 || !valid}
                onClick={() => {
                  this.handleApplyLoyaltyModal(true);
                }}
                className="uppercase"
                expand="block"
                color="secondary"
              >
                {__('Apply Loyalty')}
              </IonButton>
            )}
            {isWeb()? (
              <IonButton
                disabled={itemsCount() === 0}
                onClick={this.handleSubmit}
                color="secondary"
                expand="block"
                className={
                  'checkout-btn uppercase ' + (!isMinimumOrderTotalSatisfied() ? 'greyed' : '')
                }
              >
                {__('Checkout')}
              </IonButton>
            ) : null}
            {!isWeb() && getConfig()?.prebuildPayment?.showOnNative ? (
              <IonButton
                onClick={() => {
                  Basket.createStripeOrder(getConfig().prebuildPayment?.label);
                }}
                disabled={itemsCount() === 0}
                color="secondary"
                expand="block"
                className={
                  'checkout-btn uppercase ' + (!isMinimumOrderTotalSatisfied() ? 'greyed' : '')
                }
              >
                {__(getConfig().prebuildPayment?.label)}
              </IonButton>
            ) : null}
            { !isWeb() && getConfig().nativePayment?.isVisible ? (
							<IonButton
								onClick={() => {
									this.handleNativeSubmit();
								}}
                disabled={itemsCount() === 0}
                color="secondary"
                expand="block"
                className={
                  'checkout-btn uppercase ' + (!isMinimumOrderTotalSatisfied() ? 'greyed' : '')
                }
							>
								{__(getConfig().nativePayment?.label)}
							</IonButton>
						) : null }
            <Modal
              cssClass="table-number-modal"
              isOpen={this.state.tableNumberModalOpen}
              onDidDismiss={() =>
                this.setState({
                  tableNumberModalOpen: false,
                  tableNumberValue: Basket.getTableNumber(),
                })
              }
            >
              <Subtitle className="centered">{__('Change Table Number')}</Subtitle>
              <Spacer size={1} />
              <IonItem>
                {selectedResturant && selectedResturant.length > 0 ? (
                  <SelectOption
                    display="center"
                    label="Table Number"
                    inputStyle="box"
                    setText={__('OK')}
                    cancelText={__('Cancel')}
                    data={this.formatTableNumberForSelect(Basket.getRestaurant())}
                    value={this.state.tableNumberValue}
                    onSet={(e, a) => this.selectTableNumber(e, a)}
                    disabled={(this.state.totalTableNumbers || []).length <= 1 ? true : false}
                  />
                ) : (
                  <>
                    <IonInput
                      className="change-table-number-input"
                      onIonChange={(event) => {
                        this.setState({ tableNumberValue: event.target.value });
                      }}
                      rows={5}
                      value={this.state.tableNumberValue}
                    ></IonInput>
                  </>
                )}
              </IonItem>
              <Spacer size={1} />
              <IonButton
                color="secondary"
                expand="block"
                className="uppercase"
                onClick={() => {
                  this.tableNumberChanged();
                }}
              >
                {' '}
                {__('Confirm')}
              </IonButton>
            </Modal>
          </div>
        </div>

        <Modal
          cssClass="quantity-modal"
          title={__('Change quantity')}
          action={this.updateItemQuantity}
          actionLabel={__('Change')}
          isOpen={!!quantityModal}
          onDidDismiss={() => this.setState({ quantityModal: null })}
        >
          {quantityModal && quantityModal.item ? (
            <Incrementer
              allowNegative={false}
              quantity={quantityModal.item.quantity}
              onUpdate={this.onIncrementerUpdate}
            />
          ) : null}
        </Modal>
        <Modal
          cssClass="allergens-modal"
          isOpen={allergenModalOpen}
          onDidDismiss={() => this.handleAllergensModal(false)}
        >
          <ApplyAllergens
            handleAllergensModal={this.handleAllergensModal}
            allergenModalOpen={allergenModalOpen}
          />
          <div></div>
        </Modal>

        <Modal
          cssClass="apply-points-modal"
          isOpen={applyPointsModalOpen}
          onDidDismiss={() => this.handleApplyModal(false)}
        >
          <ApplyPoints
            handleApplyModal={this.handleApplyModal}
            applyPointsModalOpen={applyPointsModalOpen}
          />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { basketUpdated, deliveryOption, selectedSubscription } = store.orders;
  const { auth } = store.profile;
  return {
    basketUpdated,
    profile: store.profile.profile,
    cards: store.orders.cards || [],
    deliveryOption,
    itemAllergens: store.restaurants.itemAllergens,
    auth,
    isCollection: deliveryOption !== null && deliveryOption.id === 'collection',
    selectedSubscription,
    subscriptions: store.subscription.subscriptions,
  };
};

const mapDispatchToProps = {
  setOrderTypeMembership,
  setOrderTypeRegular,
  setOrderSubscriptionFlow,
  setSelectedSubscription,
  getSubscriptions,
};

export const OrderSummary = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation(OrderSummaryRaw)));
class OrderSummaryWrapped extends React.Component {
  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromItemDetails
    ) {
      // skip item details page when we going back from order summary
      go(-2);
    } else if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipBackToThePreviousPage
    ) {
      forwardTo('/order');
    } else {
      goBack();
    }
  };
  render() {
    const { __ } = this.props;
    return (
      <Loading>
        <Layout
          hideSecondToolbar={true}
          headerTitle={__('Order Summary')}
          backHandler={this.backHandler}
          scrollY={false}
        >
          <OrderSummary />
        </Layout>
      </Loading>
    );
  }
}

export default withTranslation(OrderSummaryWrapped);
