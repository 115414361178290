import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import Mobiscroll from '../../components/mobiscroll';
import Basket from '../../lib/basket';
import {
  Title,
  FieldError,
  Spacer,
  Sectiontitle,
  NormalText,
  StrongText,
  SmallText,
} from '../../components/common';
import {
  IonButton,
  IonItem,
  IonInput,
  IonList,
  IonRadioGroup,
  IonLabel,
  IonRadio,
  IonCheckbox,
  IonAlert,
} from '@ionic/react';
import {
  isDefined,
  checkForDeliveryOption,
  isWebConfig,
  deepCopy,
  isObject,
  parseAllergenData,
  forwardTo,
  goBack,
} from '../../lib/utils';
import moment from '../../lib/moment';
import {
  setCommonModal,
  getRestaurants,
  getIkentooMenu,
  getIkentooMenusForLocation,
  setDeliveryOption,
  passedTheOrder,
  showToast,
} from '../../store/actions';
import { SET_RESTAURANT_PROP } from '../../store/constants';
import NoData from '../../components/noData';
import { getMenusForSelectedTime, formatDataForTime } from '../clickAndCollect';
import './index.css';
import { getConfig } from '../../appConfig';
import api from '../../lib/api';
import { Capacitor } from '@capacitor/core';
const { SelectOption } = Mobiscroll;
const isWeb = () => Capacitor.getPlatform() === 'web';

class OrderToTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRestaurant:
        props.location.state && props.location.state.selectedRestaurant
          ? this.props.restaurants.find(
              (restaurant) => restaurant.id === props.location.state.selectedRestaurant.id,
            )
          : null,
      error: '',
      isLocationAllowed: false,
      tableNumber:
        props.location.state && props.location.state.selectedRestaurant
          ? props.location.state.selectedRestaurant.table_name
          : getConfig().flags.tableNumberPicker
          ? null
          : '',
      showTableNumber: false,
      continueButtonClicked: false,
      totalTableNumbers: [],
      tableNumberPicker: getConfig().flags.tableNumberPicker || false,
      isSubmitRestaurantAlertOpen: false,
    };
  }

  selectTableNumber = (event, data) => {
    const tableId = data.getVal();
    this.setState({
      tableNumber: tableId,
    });
  };
  formatTableNumberForSelect = (selectedRestaurantId) => {
    const { __ } = this.props;
    let arrForSelect = [];
    if (selectedRestaurantId) {
      (this.state.totalTableNumbers || []).map((table, index) => {
        arrForSelect.push({ text: table.name, value: table.value });
        return null;
      });
    }
    return [{ text: __('Select table'), value: null }, ...arrForSelect];
  };
  componentDidMount() {
    const { restaurants } = this.props;
    Basket.setOrderType('table');
    if (this.state.selectedRestaurant) {
      Basket.setRestaurant(
        restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurant.id),
      );
      this.selectRestaurant(
        restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurant.id),
      );

      Basket.setServicePercentage(
        this.props.location.state.selectedRestaurant.service_charge_percentage,
      );
      Basket.setCollectionTime(null);
      const tableNumbers = restaurants.find(
        (restaurant) => restaurant.id === this.state.selectedRestaurant,
      ).table_plan_json;
      this.setState({
        totalTableNumbers: tableNumbers,
      });
      this.setState({
        pickTime: moment(Date.now()).format('HH:mm'),
      });
    } else {
      // const filteredRestaurants = restaurants.filter(
      //   (store) =>
      //     isDefined(store.can_table_order) &&
      //     isDefined(store.is_published) &&
      //     store.can_table_order &&
      //     store.is_published,
      // );
      // if (filteredRestaurants.length > 0) {
      //   Basket.setRestaurant(filteredRestaurants[0]);
      //   this.selectRestaurant(filteredRestaurants[0]);
      // }
    }
    const deliveryOption = checkForDeliveryOption(Basket.getDeliveryOption(), '/order-to-table');
    if (deliveryOption) {
      this.props.dispatch(getRestaurants());
      this.props.dispatch(setDeliveryOption(deliveryOption));
    }
    if (Basket.getMenu()) {
      this.setState({ selectedIkentooMenu: Basket.getMenu() });
    }
    if (isWeb()) {
      this.position();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    checkForDeliveryOption(this.props.deliveryOption, '/order-to-table');
    if (this.props.ikentooMenusForLocation.length !== prevProps.ikentooMenusForLocation.length) {
      if (this.props.ikentooMenusForLocation[0]) {
        this.setState({ selectedIkentooMenu: this.props.ikentooMenusForLocation[0].ikentooMenuId });
      }
    }

    if (
      this.state.selectedRestaurant !== prevState.selectedRestaurant &&
      this.state.selectedRestaurant
    ) {
      const tableNumbers = this.state.selectedRestaurant.table_plan_json;

      this.setState({
        totalTableNumbers: tableNumbers,
      });
    }
  }
  getRestautantDataMessage = (restaurant) => {
    return `<p><b>${restaurant?.name}</b></p> \n <p>${restaurant?.address}</p>`;
  };
  selectRestaurant = (selectedRestaurant) => {
    const { restaurants, profile } = this.props;
    this.setState(
      { selectedRestaurant, pickTime: moment(Date.now()).format('HH:mm'), showTableNumber: true },
      () => {
        Basket.reset(profile.cardToken);
        Basket.setRestaurant(
          restaurants.find((restaurant) => restaurant.id === selectedRestaurant.id),
        );
        if (Basket.getRestaurant()) {
          if (getConfig().general.hasServiceCharge) {
            if (getConfig().flags.applyAutomaticServiceCharge) {
              if (Basket.getRestaurantServiceCharge()) {
                const service_charge_original = Basket.getRestaurantServiceCharge();
                Basket.setServicePercentage(parseInt(service_charge_original[1] * 100));
              } else {
                const defaultServiceCharge = getConfig().general.defaultServiceCharge;
                Basket.setServicePercentage(parseInt(defaultServiceCharge[1] * 100));
              }
            } else {
              Basket.setServicePercentage(0);
            }
          } else {
            Basket.setServicePercentage(0);
          }
        }
        Basket.setCollectionTime(null);
        Basket.setOrderType('table');
      },
    );
  };

  position = async () => {
    await navigator.geolocation.getCurrentPosition(
      () => {
        this.setState({
          isLocationAllowed: true,
        });
      },
      (err) => console.log(err),
    );
  };

  formatDataForSelect = (stores) => {
    const { myLocation } = this.props;
    let arrForSelect = [];
    stores.forEach((store) => {
      if (
        isDefined(store.can_table_order) &&
        isDefined(store.is_published) &&
        store.can_table_order &&
        store.is_published
      ) {
        const currentDT = moment();
        let minDT = currentDT;
        if (store && isDefined(store.order_slot_lead_time)) {
          minDT.add(store.order_slot_lead_time, 'minutes');
        }
        const timeData = formatDataForTime(store, minDT, store.id, false, true, []);
        store.opened =
          timeData.length === 1 && timeData[0].text.toLowerCase() === 'closed' ? 'Closed' : 'Open';
        arrForSelect.push(store);
      }
    });
    if (this.state.isLocationAllowed || (myLocation?.latitude && myLocation?.longitude)) {
      arrForSelect.sort(function (a, b) {
        return a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0;
      });
    } else {
      arrForSelect.sort(function (a, b) {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
      });
    }
    return arrForSelect;
  };
  getMenuForReorder = (menus, reorderItems) => {
    let menuForReorder = [];
    for (let i = 0; i < menus.length; i++) {
      let foundItems = 0;
      const categoryItems = menus[i].menuEntry || menus[i].menuEntryGroups || null;
      let items = Basket.flattenMenuItems(deepCopy(categoryItems));
      menus[i].flattenMenuItems = items;
      if (items.length > 0) {
        for (let j = 0; j < reorderItems.length; j++) {
          let foundItem = items.find((i) => i.sku === reorderItems[j].item.sku);
          if (foundItem) {
            foundItems = foundItems + 1;
          }
        }
      }
      menus[i].foundItems = foundItems;
      menuForReorder.push(menus[i]);
    }
    menuForReorder.sort((a, b) => b.foundItems - a.foundItems);
    if (menuForReorder[0] && menuForReorder[0].foundItems > 0) {
      return this.filterMenu(menuForReorder[0]);
    } else {
      return false;
    }
  };
  chooseMenusForLocation = async () => {
    const { restaurants, dispatch } = this.props;
    const { selectedRestaurant, pickTime, tableNumber } = this.state;
    this.setState({ continueButtonClicked: true }, async () => {
      Basket.setPassedOrder(true);
      if (selectedRestaurant && pickTime) {
        const choosenRestaurant = restaurants.find(
          (restaurant) => restaurant.id === selectedRestaurant.id,
        );
        const businessLocationId = choosenRestaurant.business_location_id;
        let cutoffTimeRes = moment().unix();
        Basket.setCutoffTime(cutoffTimeRes);
        Basket.setTableNumber(tableNumber);
        if (this.props.location.state && this.props.location.state.selectedRestaurant) {
          let ikentooMenusForLocation = await api.getIkentooMenusForLocation(businessLocationId);
          ikentooMenusForLocation = getMenusForSelectedTime(
            ikentooMenusForLocation,
            pickTime,
            choosenRestaurant.table_json_time_selector,
          );
          if (ikentooMenusForLocation && isObject(ikentooMenusForLocation[0])) {
            let ikentooMenus = [];
            try {
              for (let i = 0; i < ikentooMenusForLocation.length; i++) {
                ikentooMenus.push(
                  await api.getIkenooMenu(
                    ikentooMenusForLocation[i].ikentooMenuId,
                    businessLocationId,
                  ),
                );
              }
            } catch (error) {
              this.setState({ error: 'Get restaurant menu error.' });
            }
            const reorderItems = this.props.location.state.selectedRestaurant.items;
            const menu = this.getMenuForReorder(ikentooMenus, reorderItems);
            if (menu) {
              dispatch({
                type: SET_RESTAURANT_PROP,
                key: 'ikentooMenu',
                value: menu,
              });
              if (menu.flattenMenuItems.length > 0) {
                let validationStatus = { notBasketEmpty: false, validationError: false };
                reorderItems.map((newBasketItem, index) => {
                  let foundItem = menu.flattenMenuItems.find(
                    (i) => i.sku === newBasketItem.item.sku,
                  );
                  if (foundItem && Basket.isProductEnabled(foundItem)) {
                    newBasketItem.item = foundItem;
                    Basket.addToBasket(newBasketItem);
                    let item = newBasketItem.item;
                    let allergens = this.props.allergens;
                    let profile = this.props.profile;
                    let newArr = parseAllergenData(profile, item, allergens);
                    let allergensCodes =
                      newBasketItem.item?.itemRichData?.allergenCodes?.length > 0
                        ? newBasketItem.item.itemRichData.allergenCodes
                        : [];
                    if (allergensCodes.length > 0) {
                      let allergensData = [{ allergens: newArr }, { sku: newBasketItem.item.sku }];
                      Basket.setAllergen(allergensData);
                    }
                    validationStatus.notBasketEmpty = true;
                  } else {
                    validationStatus.validationError = true;
                  }
                  return null;
                });
                if (validationStatus.notBasketEmpty) {
                  if (validationStatus.validationError) {
                    dispatch(
                      showToast(
                        'Some items were not added to your basket as they are currently unavailable',
                        'warning',
                      ),
                    );
                    this.setState({ continueButtonClicked: false });
                  }
                  forwardTo('/order-summary', { skipBackToThePreviousPage: false });
                } else {
                  dispatch(
                    showToast(
                      'Some items were not added to your basket as they are currently unavailable',
                      'warning',
                    ),
                  );
                  this.setState({ continueButtonClicked: false });
                }
              }
            } else {
              dispatch(showToast('Menu not found', 'warning'));
              this.setState({ continueButtonClicked: false });
            }
          } else {
            dispatch(showToast('This service is currently unavailable!', 'warning'));
            this.setState({ continueButtonClicked: false });
          }
        } else {
          dispatch(
            getIkentooMenusForLocation(businessLocationId, {
              pickTime,
              json_time_selector: choosenRestaurant
                ? choosenRestaurant.table_json_time_selector
                : [],
            }),
          );
          this.setState({ continueButtonClicked: false });
        }
      } else if (!selectedRestaurant) {
        this.setState({ error: 'Please select location' });
        this.setState({ continueButtonClicked: false });
      } else {
        this.setState({ error: 'Please select pickup time' });
        this.setState({ continueButtonClicked: false });
      }
    });
  };

  changeIkentooMenus = (event) =>
    this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
      Basket.setMenu(event.detail.value);
    });

  continueOnMenu = () => {
    const { restaurants, dispatch } = this.props;
    const { selectedIkentooMenu, selectedRestaurant } = this.state;
    if (selectedRestaurant && selectedIkentooMenu) {
      const choosenRestaurant = restaurants.find(
        (restaurant) => restaurant.id === selectedRestaurant.id,
      );
      const businessLocationId = choosenRestaurant.business_location_id;
      dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
      dispatch(passedTheOrder(true));
      Basket.setPassedOrder(true);
    } else {
      this.setState({ error: 'Please select location menu' });
    }
  };
  getKitchenStatusColor(status) {
    let color = '';
    switch (status) {
      case 0:
        color = 'success';
        break;
      case 1:
        color = 'warning';
        break;
      case 2:
        color = 'danger';
      default:
        color = '';
    }
    return color;
  }
  backHandler = () => {
    if (this.state.showTableNumber) {
      goBack();
    } else {
      this.setState({ showTableNumber: true });
    }
  };
  filterIkentooMenuItems = (items) => {
    let filteredItems = [];
    items.forEach((item) => {
      if (!item.menuEntry) {
        // break recursion when arrive to the product
        if (item.sku && Basket.isProductJustEnabled(item)) {
          filteredItems.push(item);
          return [item];
        } else {
          return [];
        }
      } else {
        const len = (item.menuEntry || []).length;
        if (len > 0) {
          const newFilteredItems = this.filterIkentooMenuItems(item.menuEntry);
          if (newFilteredItems.length > 0) {
            item.menuEntry = newFilteredItems;
            filteredItems.push(item);
          }
        }
      }
    });

    return filteredItems;
  };

  filterMenu = (menu) => {
    if (menu && menu.menuEntryGroups) {
      menu.menuEntryGroups = this.filterIkentooMenuItems(menu.menuEntryGroups);
      return menu;
    }
    return menu;
  };
  setTableNumberValue = (e) => {
    const value = e.target.value;
    const tableNumber = value.replace(/\D/g, '');
    this.setState({ tableNumber });
  };
  render() {
    const {
      __,
      deliveryOption,
      restaurants,
      isChooseMenuModalOpen,
      ikentooMenusForLocation,
    } = this.props;
    const {
      error,
      selectedIkentooMenu,
      pickTime,
      selectedRestaurant,
      showTableNumber,
    } = this.state;
    const stores = restaurants || [];
    const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
    const store =
      restaurants.find((restaurant) => restaurant.id === selectedRestaurant?.id) || null;
    const menus = getMenusForSelectedTime(
      ikentooMenusForLocation,
      pickTime,
      store ? store.table_json_time_selector : [],
    );
    return (
      <Layout
        backHandler={this.backHandler}
        hideSecondToolbar={true}
        headerTitle={__(deliveryOption ? deliveryOption.label : '')}
        noPadding
        color="transparent"
      >
        {!showTableNumber && (
          <div className="click-collect-layout">
            <div className="click-collect-wrapper">
              <div className="click-collect-dialog-header">
                <Title>{__('Select another location')}</Title>
              </div>
              <Spacer />
              <div className="click-collect-locations">
                <IonList>
                  {this.formatDataForSelect(stores).map((store, index) => (
                    <div className="click-collect-items" key={index}>
                      <IonItem
                        disabled={store.opened === 'Closed'}
                        onClick={() => this.setState({ selectedRestaurant: store })}
                        lines="none"
                        className="default-padding"
                      >
                        <IonLabel>
                          <StrongText>{__(store.name)}</StrongText>
                          <NormalText className="block">{__(store.address)}</NormalText>
                          <SmallText
                            className="bold"
                            color={this.getKitchenStatusColor(store.kitchen_status.status)}
                          >
                            {store.kitchen_status.text}
                          </SmallText>
                        </IonLabel>
                        <IonCheckbox
                          checked={selectedRestaurant && store.id === selectedRestaurant.id}
                          slot="start"
                          color="secondary"
                        />
                      </IonItem>
                    </div>
                  ))}
                </IonList>
              </div>
            </div>
            <div className="click-collect-button">
              <IonButton
                disabled={!selectedRestaurant}
                expand="block"
                color="secondary"
                onClick={() =>
                  this.setState({ isSubmitRestaurantAlertOpen: true, forwardToOrdering: false })
                }
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        )}
        {showTableNumber && (
          <div className="click-collect-layout">
            <div>
              <Loading transparent>
                <div className="click-collect-wrapper">
                  <div className="click-collect-dialog-header">
                    <Title className="click-collect-title">
                      {deliveryOption ? `${__(deliveryOption.label)}` : ''}
                    </Title>
                    <NormalText className="delivery-option-description">
                      {__(
                        'Start an order to be delivered to your table. This service is available for in store use only.',
                      )}
                    </NormalText>
                    <Spacer />
                  </div>
                </div>
                <div className="click-collect-dialog-content">
                  <IonList className="shadowed-box-light no-padding">
                    <IonItem className="default-padding" key={selectedRestaurant?.id} lines="none">
                      <div tabIndex="-1"></div>
                      <IonCheckbox color="secondary" slot="start" checked />
                      <IonLabel className="ion-text-wrap" color="dark">
                        <StrongText>{selectedRestaurant?.name}</StrongText>
                        <NormalText className="block">{selectedRestaurant?.address}</NormalText>
                        <SmallText
                          className="bold"
                          color={this.getKitchenStatusColor(
                            selectedRestaurant?.kitchen_status.status,
                          )}
                        >
                          {selectedRestaurant?.kitchen_status.text}
                        </SmallText>
                      </IonLabel>
                    </IonItem>
                  </IonList>
                  <div>
                    <IonButton
                      expand="block"
                      fill="clear"
                      color="secondary"
                      className="link underlined"
                      onClick={() =>
                        this.setState({
                          showTableNumber: false,
                        })
                      }
                    >
                      {__('At a different location?')}
                    </IonButton>
                  </div>
                </div>
                <Spacer size={2} />
                <div className="table-number">
                  <StrongText htmlFor="demo-non-form block">{__('Table number')}</StrongText>
                  {this.state.tableNumberPicker ? (
                    <SelectOption
                      display="center"
                      label="Table Number"
                      inputStyle="box"
                      setText={__('OK')}
                      cancelText={__('Cancel')}
                      data={this.formatTableNumberForSelect(this.state.selectedRestaurant)}
                      value={this.state.tableNumber}
                      onSet={(e, a) => this.selectTableNumber(e, a)}
                      disabled={(this.state.totalTableNumbers || []).length <= 1 ? true : false}
                    />
                  ) : (
                    <>
                      <IonInput
                        className="table-number-input"
                        onIonChange={(e) => this.setTableNumberValue(e)}
                        value={this.state.tableNumber}
                        type="text"
                        placeholder={__('Enter table number')}
                      />
                      {error ? (
                        <IonItem>
                          <div tabIndex="-1"></div>
                          <FieldError className="field-error" value={__(error)} />
                        </IonItem>
                      ) : null}
                    </>
                  )}
                  {error ? (
                    <IonItem>
                      <div tabIndex="-1"></div>
                      <FieldError className="field-error" value={__(error)} />
                    </IonItem>
                  ) : null}
                </div>
                <Spacer size={2} />
              </Loading>
              <div className="" style={{ padding: '0 var(--ion-padding)' }}>
                <IonButton
                  disabled={this.state.tableNumber ? false : true}
                  expand="block"
                  color="secondary"
                  className={this.state.continueButtonClicked ? 'unclicked' : ''}
                  onClick={() =>
                    this.setState({ isSubmitRestaurantAlertOpen: true, forwardToOrdering: true })
                  }
                >
                  {__('Continue')}
                </IonButton>
              </div>
            </div>
          </div>
        )}
        <div className={`click-collect-dialog ${animationMenuClass}`}>
          <div className="click-collect-dialog-layout sc-ion-modal-md">
            <div className="click-collect-dialog-header">
              <h3>{__('Choose menu')}</h3>
            </div>
            <div
              className="click-collect-dialog-closer"
              style={{ position: 'absolute', right: 0, top: 0 }}
              onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
            >
              <ion-icon
                name="close"
                role="img"
                className="md hydrated"
                aria-label="close"
              ></ion-icon>
            </div>
            <div className="click-collect-dialog-content">
              <IonList lines="none">
                <IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
                  {menus.length === 0 ? (
                    <NoData />
                  ) : (
                    menus.map((menu) => {
                      const { ikentooMenuId, menuName } = menu;
                      return (
                        <IonItem key={ikentooMenuId} lines="full">
                          <div tabIndex="-1"></div>
                          <IonLabel className="ion-text-wrap">
                            <Sectiontitle>{menuName}</Sectiontitle>
                          </IonLabel>
                          <IonRadio
                            color={isWebConfig() ? 'secondary' : 'white'}
                            slot="start"
                            value={ikentooMenuId}
                          />
                        </IonItem>
                      );
                    })
                  )}
                </IonRadioGroup>
              </IonList>
            </div>
            <div className="click-collect-dialog-action">
              {error ? (
                <IonItem>
                  <div tabIndex="-1"></div>
                  <FieldError className="field-error" value={__(error)} />
                </IonItem>
              ) : null}
              <IonButton
                disabled={pickTime && menus.length > 0 ? false : true}
                expand="block"
                color="secondary"
                className="uppercase okx-font-secondary round-button"
                onClick={() => this.continueOnMenu()}
              >
                {__('Continue')}
              </IonButton>
            </div>
          </div>
        </div>
        <IonAlert
          isOpen={this.state.isSubmitRestaurantAlertOpen}
          onDidDismiss={() => this.setState({ isSubmitRestaurantAlertOpen: false })}
          header={__('Please confirm you are in this location')}
          message={__(this.getRestautantDataMessage(selectedRestaurant))}
          buttons={[
            {
              text: this.state.forwardToOrdering ? __('Change') : __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () =>
                this.state.forwardToOrdering
                  ? this.setState({ showTableNumber: false, forwardToOrdering: false })
                  : this.setState({ isSubmitRestaurantAlertOpen: false }),
            },
            {
              text: __('Confirm'),
              role: 'submit',
              cssClass: 'secondary',
              handler: () =>
                this.state.forwardToOrdering
                  ? this.chooseMenusForLocation()
                  : this.selectRestaurant(selectedRestaurant),
            },
          ]}
        />
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { deliveryOption } = state.orders;
  const { restaurants, ikentooMenusForLocation } = state.restaurants;
  const { isChooseMenuModalOpen, myLocation } = state.common;
  return {
    deliveryOption,
    restaurants: restaurants || [],
    profile: state.profile.profile,
    isChooseMenuModalOpen: isChooseMenuModalOpen,
    ikentooMenusForLocation: ikentooMenusForLocation || [],
    myLocation: myLocation,
  };
};

export default connect(stateToProps)(withTranslation(OrderToTable));
