import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonBadge,
} from '@ionic/react';
import Layout from '../../components/layout';
import PromoBar from '../../components/promoBar';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined, isEmptyObject, forwardTo, getSingleDeliveryOption } from '../../lib/utils';
import { getConfig } from '../../appConfig';
import { NormalText, Title } from '../../components/common';
import dashboardImage from '../../assets/images/dash-logo.png'
const { hasOrdering, hasLoyalty, hasCampaignManager } = getConfig().appType;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      singleDelivery: getSingleDeliveryOption(),
    };
  }

  render() {
    const { __, screenName, vouchers } = this.props;
    const { singleDelivery } = this.state;
    let voucherRes = (vouchers || []).filter((data) => {
      if (data.type === 1) {
        return data;
      }
      return false;
    });
    return (
      <Layout hideSecondToolbar={true} color="transparent" noPadding={true}>
        <div className="absolute-content dash-layout"></div>
        <IonCard className="dash-card">
          <IonCardHeader>
            <IonCardTitle className="ion-text-center" color="primary">
              <NormalText className='centered'>{__('Welcome Back')}</NormalText>
              <Title className='centered user-screen-name' >{screenName}</Title>
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent className="dash-menu">
            <IonList>
              {hasOrdering ? (
                singleDelivery ? (
                  <IonItem className="clickable" onClick={() => forwardTo('/click-and-collect')}>
                    <div tabIndex="-1"></div>
                    <IonLabel>{__(singleDelivery.label)}</IonLabel>
                  </IonItem>
                ) : (
                  <IonItem className="clickable" onClick={() => forwardTo('/delivery-options')}>
                    <div tabIndex="-1"></div>
                    <IonLabel>{__('Start New Order')}</IonLabel>
                  </IonItem>
                )
              ) : null}
              {hasLoyalty ? (
                <IonItem className="clickable" onClick={() => forwardTo('/loyalty')}>
                  <div tabIndex="-1"></div>
                  <IonLabel>{__('Loyalty')}</IonLabel>
                </IonItem>
              ) : null}
              {hasCampaignManager ? (
                <IonItem className="clickable" onClick={() => forwardTo('/vouchers')}>
                  <div tabIndex="-1"></div>
                  <IonLabel>
                    {__('Vouchers')}
                    {voucherRes && voucherRes.length === 0 ? null : (
                      <IonBadge className="small">
                        {voucherRes.length}
                      </IonBadge>
                    )}
                    <IonBadge color="secondary" className="small">
                      { }
                    </IonBadge>
                  </IonLabel>
                </IonItem>
              ) : null}
              <IonItem className="clickable" onClick={() => forwardTo('/account')} lines="none">
                <div tabIndex="-1"></div>
                <IonLabel>{__('My Account')}</IonLabel>
              </IonItem>
              {/* <IonItem className="clickable" onClick={ () => forwardTo('/locations') } lines="none">
								<IonLabel>{ __('Locations') }</IonLabel>
							</IonItem> */}
            </IonList>
            <img className='dashboard-image' alt=' ' src={dashboardImage} />
          </IonCardContent>
        </IonCard>
        {hasCampaignManager ? <PromoBar type={__('info')} vouchers={voucherRes} /> : null}
      </Layout>
    );
  }
}

const stateToProps = (state) => {
  const { profile, vouchers } = state.profile;
  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name} ${profile.last_name}`;
  }
  return {
    screenName,
    vouchers: vouchers || [],
  };
};

export default connect(stateToProps)(withTranslation(Dashboard));
