import React, { Component } from 'react';
import { IonRow, IonCol, IonButton, IonInput, IonItem, IonTextarea, IonIcon } from '@ionic/react';
import { pencil } from 'ionicons/icons';
import successIcon from '../../assets/images/icons/success-icon.svg';
import { withTranslation } from '../../lib/translate';
import {
  BigLabel,
  FieldError,
  NormalText,
  Spacer,
  StrongText,
  Subtitle,
  Title,
} from '../../components/common';

import { redeemGiftVoucher } from '../../store/actions';
import { forwardTo } from '../../lib/utils';
import Basket from '../../lib/basket';
import { CLEAR_REDEEMED_GIFT_VOUCHER } from '../../store/constants';

class RedeemGiftVoucher extends Component {
  state = {
    reedemGiftCode: '',
    showSuccess: false,
  };

  redeemGiftVoucher = () => {
    const { dispatch } = this.props;
    const { reedemGiftCode } = this.state;
    dispatch(redeemGiftVoucher({ code: reedemGiftCode }));
  };
  componentDidUpdate() {
    if (this.props.giftVoucherRedeemed) {
      this.setState({
        showSuccess: true,
        reedemGiftCode: '',
      });
      forwardTo('/gift-vouchers');
    }
  }
  render() {
    const { __, redeemedGiftVoucher, dispatch } = this.props;
    const { giftVoucherError, showSuccess } = this.state;
    return (
      <>
        {showSuccess ? (
          <div className="success-gift-voucher centered">
            <Title className="default-padding centered">{__('Success')}</Title>
            <Spacer />
            <img src={successIcon}></img>
            <Spacer />
            {redeemedGiftVoucher && (
              <>
                <NormalText>
                  `
                  <StrongText>
                    {redeemedGiftVoucher.points_value} {__('points')}
                  </StrongText>{' '}
                  {__('have been added to your loyalty balance worth')}{' '}
                  <StrongText>
                    {Basket.formatPrice(redeemedGiftVoucher.points_value / 100)}
                  </StrongText>
                  `
                </NormalText>
                {redeemedGiftVoucher.personal_message !== '' && (
                  <>
                    <Spacer />
                    <NormalText>{__('The sender attached the following message')}:</NormalText>
                    <Spacer />
                    <NormalText>{redeemedGiftVoucher.personal_message}</NormalText>
                    <Spacer />
                  </>
                )}
              </>
            )}
            {/* {redeemedGiftVoucher.points_value}{__('have been added to your loyalty balance worth')}{' '}{} */}
            <Spacer size={1} />
            <IonButton
              expand="block"
              color="primary uppercase default-padding"
              onClick={() => {
                this.setState({ showSuccess: false });
                dispatch({ type: CLEAR_REDEEMED_GIFT_VOUCHER });
                forwardTo('/history');
              }}
            >
              {__('View Loyalty')}
            </IonButton>
          </div>
        ) : (
          <div className="recieve-voucher-wrapper">
            <Title className="default-padding">{__('Redeem gift voucher')}</Title>
            <Spacer size={1} />

            <div className="voucher-input-wrapper">
              <IonInput
                onIonChange={(e) => this.setState({ reedemGiftCode: e.target.value })}
                value={this.state.reedemGiftCode}
                placeholder={__('Enter your gift code')}
              ></IonInput>

              <IonButton onClick={() => this.redeemGiftVoucher()} color="secondary uppercase">
                {__('Redeem')}
              </IonButton>
            </div>
            {giftVoucherError && (
              <FieldError className="default-padding field-error" value={__('Incorrect code')} />
            )}
          </div>
        )}
      </>
    );
  }
}

export default withTranslation(RedeemGiftVoucher);
