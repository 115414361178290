import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonGrid, IonRow, IonCol, IonItem, IonInput, IonIcon } from '@ionic/react';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { isDefined, isEmptyObject, forwardTo, goBack } from '../../lib/utils';
import Loading from '../../components/spinner';
import OrderList from './orderList';
import Basket from '../../lib/basket';
import { setScrollTop } from '../../store/actions';
import ContentHeader from '../../components/contentHeader';
import './index.css';
import Modal from '../../components/modal';
import { Spacer, Subtitle, Title } from '../../components/common';
import Mobiscroll from '../../components/mobiscroll';
import { close, search } from 'ionicons/icons';
import NoDataSecond from '../../components/noDataSecond';
import { getConfig } from '../../appConfig';
// import { NormalText, SmallText, Spacer } from '../../components/common';
const { SelectOption } = Mobiscroll;
const { getDeliveryOption, getDeliveryAddress, getPickUpPoint } = Basket;

class OrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: 0,
      ikentooMenu: null,
      menuRefs: null,
      categoriesPositionTop: [],
      preventScroll: false,
      tableNumberValue: Basket.getTableNumber(),
      tableNumberModalOpen: false,
      originalMenu: null,
      searchModalOpen: false,
      searchedValueModal: '',
      searchItemsValue: '',
    };
    this.selectCategoryOnScroll = this.selectCategoryOnScroll.bind(this);
  }
  tableNumberChanged = () => {
    if (this.state.tableNumberValue !== '') {
      Basket.setTableNumber(this.state.tableNumberValue);
    }
    this.setState({ tableNumberModalOpen: false });
  };
  tableNumberHandler = () => {
    this.setState({ tableNumberModalOpen: true });
  };
  setIkentooMenu = (menu) =>
    this.setState(
      {
        ikentooMenu:
          menu || (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu))
            ? this.props.defaultMenu
            : this.props.ikentooMenu,
        originalMenu:
          menu || (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu))
            ? this.props.defaultMenu
            : this.props.ikentooMenu,
      },
      () => {
        let menuRefs = this.menuRefs(this.state.ikentooMenu);
        this.setState({ menuRefs });
      },
    );

  componentDidMount() {
    this.setIkentooMenu();
    const tableNumbers = Basket.getRestaurant()?.table_plan_json;
    this.setState({
      totalTableNumbers: tableNumbers,
    });
  }

  shouldComponentUpdate(nextProps) {
    const prevMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
      ? nextProps.defaultMenu
      : nextProps.ikentooMenu || {}
    ).menuName;
    const currentMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
      ? this.props.defaultMenu
      : this.props.ikentooMenu || {}
    ).menuName;
    if (
      prevMenuName !== currentMenuName ||
      nextProps.restaurantsUpdated !== this.props.restaurantsUpdated
    ) {
      this.setIkentooMenu(
        Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
          ? nextProps.defaultMenu
          : nextProps.ikentooMenu,
      );
    }
    return true;
  }

  menuRefs = (menuRefs) => {
    return menuRefs && menuRefs.menuEntryGroups
      ? menuRefs.menuEntryGroups.reduce((acc, value, index) => {
          let name = `${value.name}_${index}`;
          acc[name] = React.createRef();
          return acc;
        }, {})
      : {};
  };

	scrollTo = (name) => this.state.menuRefs[name].current.scrollIntoView();

  getClosestPosition = (arr, pos) => {
    let closest = 0;
    arr.some((a) => {
      if (a > pos) {
        return true;
      }
      closest = a;
      return false;
    });
    return closest;
  };

  selectCategoryOnScroll = (e) => {
    e.preventDefault();
    if (!this.state.preventScroll) {
      const positionTop =
        e.target.scrollTop + document.querySelector('.order-categories-inner').clientHeight;
      const { menuRefs } = this.state;
      const positions = Object.keys(menuRefs).map((key) => {
        return menuRefs[key].current.offsetTop;
      });
      let selectCategory = 0;
      let closest = this.getClosestPosition(positions, positionTop);
      positions.forEach((item, i) => {
        if (item === closest && positionTop >= item) {
          selectCategory = i;
        }
      });
      if (document) {
        const catDiv = document.querySelector('.order-categories-inner');
        if (catDiv && catDiv.childNodes && catDiv.childNodes[selectCategory]) {
          setTimeout(() => {
            catDiv.childNodes[selectCategory].scrollIntoView({
              behavior: 'smooth',
              inline: 'start',
            });
          }, 500);
        }
      }
      this.props.dispatch(setScrollTop(e.target.scrollTop));
      this.setState({ selectedCategory: selectCategory, scrollTop: e.target.scrollTop });
    }
    this.setState({ preventScroll: false });
  };

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipBackToThePreviousPage
    ) {
      forwardTo('/delivery-options');
    } else {
      goBack();
    }
  };
  formatTableNumberForSelect = (selectedRestaurantId) => {
    const { __ } = this.props;
    let arrForSelect = [];
    if (selectedRestaurantId) {
      (this.state.totalTableNumbers || []).map((table) => {
        arrForSelect.push({ text: table.name, value: table.value });
      });
    }
    return [{ text: __('Select table'), value: null }, ...arrForSelect];
  };
  selectTableNumber = (event, data) => {
    const tableId = data.getVal();
    this.setState({
      tableNumberValue: tableId,
    });
  };
  filterMenu(menu) {
    if (menu && menu.menuEntryGroups) {
      menu.menuEntryGroups = this.filterIkentooMenuItems(menu.menuEntryGroups);
      return menu;
    }
    return menu;
  }
  filterIkentooMenuItems(items) {
    let filteredItems = [];
    items.forEach((item) => {
      if (!item.menuEntry) {
        // break recursion when arrive to the product
        if (item.productName.toLowerCase().includes(`${this.state.searchItemsValue}`)) {
          filteredItems.push(item);
          return [item];
        } else {
          return [];
        }
      } else {
        const len = (item.menuEntry || []).length;
        if (len > 0) {
          const newFilteredItems = this.filterIkentooMenuItems(item.menuEntry);
          if (newFilteredItems.length > 0) {
            item.menuEntry = newFilteredItems;
            filteredItems.push(item);
          }
        }
      }
    });

    return filteredItems;
  }
  filterMenuItemsTest = (e) => {
    this.setState({ searchItemsValue: e.target.value }, () => {
      const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
      const filtered = this.filterMenu(menu);
      this.setState({ ikentooMenu: filtered }, () => {
        let menuRefs = this.menuRefs(this.state.ikentooMenu);
        this.setState({ menuRefs });
      });
    });
  };
  getSearchedItems = () => {
    this.setState(
      { searchItemsValue: this.state.searchedValueModal, searchModalOpen: false },
      () => {
        const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
        const filtered = this.filterMenu(menu);
        this.setState({ ikentooMenu: filtered }, () => {
          let menuRefs = this.menuRefs(this.state.ikentooMenu);
          this.setState({ menuRefs });
        });
      },
    );
  };
  filterMenuItems = (e) => {
    this.setState({ searchItemsValue: e.target.value }, () => {
      if (getConfig().menuDisplayType !== 1) {
        if (this.state.selectedMenuType != 1 && this.state.searchItemsValue !== '') {
          this.setState({ selectedMenuType: 1 }, () => {
            const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
            const filtered = this.filterMenu(menu);
            this.setState({ ikentooMenu: filtered }, () => {
              let menuRefs = this.menuRefs(this.state.ikentooMenu);
              this.setState({ menuRefs });
            });
          });
        } else if (this.state.selectedMenuType == 1 && this.state.searchItemsValue == '') {
          this.setState({ selectedMenuType: getConfig().menuDisplayType }, () => {
            const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
            const filtered = this.filterMenu(menu);
            this.setState({ ikentooMenu: filtered }, () => {
              let menuRefs = this.menuRefs(this.state.ikentooMenu);
              this.setState({ menuRefs });
            });
          });
        } else {
          const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
          const filtered = this.filterMenu(menu);
          this.setState({ ikentooMenu: filtered }, () => {
            let menuRefs = this.menuRefs(this.state.ikentooMenu);
            this.setState({ menuRefs });
          });
        }
      } else {
        const menu = JSON.parse(JSON.stringify(this.state.originalMenu));
        const filtered = this.filterMenu(menu);
        this.setState({ ikentooMenu: filtered }, () => {
          let menuRefs = this.menuRefs(this.state.ikentooMenu);
          this.setState({ menuRefs });
        });
      }
    });
  };
  render() {
    const { __, scrollTop, isPassedOrder } = this.props;
    const { selectedCategory, ikentooMenu } = this.state;
    let categories = !isEmptyObject(ikentooMenu) ? ikentooMenu.menuEntryGroups : [];
    const labelTitle =
      Basket.getOrderType() === 'Click & Collect' ? 'Click & Collect Order' : Basket.getOrderType();
    const selectedResturant = Basket.getRestaurant()?.table_plan_json;
    return (
      <>
        <Layout
          noPadding
          scrollY={false}
          hideSecondToolbar={true}
          headerTitle={__(labelTitle)}
          headerWithTitle={true}
        >
          {!isDefined(ikentooMenu) ? null : (
            <div className="segment-holder">
              <div className="order-header-content-wrapper">
                {isPassedOrder || Basket.getPassedOrder() ? (
                  <>
                    <ContentHeader
                      __={__}
                      deliveryOption={getDeliveryOption() || {}}
                      orderType={labelTitle}
                      deliveryAddress={getDeliveryAddress()}
                      pickUpPoint={getPickUpPoint()}
                      tableNumberHandler={this.tableNumberHandler}
                    />
                  </>
                ) : null}
                <div className="search-box">
                  <IonIcon color="secondary" icon={search}></IonIcon>
                  <IonInput
                    placeholder={__('Search products')}
                    value={this.state.searchItemsValue}
                    onIonChange={this.filterMenuItems}
                  />
                  <IonIcon
                    onClick={() => this.setState({ searchItemsValue: '', selectedMenu: [] })}
                    className="reset-icon"
                    color="secondary"
                    icon={close}
                  ></IonIcon>
                </div>
              </div>
              <div className="order-categories">
                {categories.length > 0 ? (
                  <>
                    <div className="order-categories-inner">
                      {categories
                        .filter((el) => el.menuEntry.length > 0)
                        .map((category, index) => {
                          return (
                            <IonButton
                              key={index}
                              size="small"
                              fill="clear"
                              className={
                                selectedCategory === index
                                  ? 'category-button active'
                                  : 'category-button'
                              }
                              onClick={() => this.scrollTo(`${category.name}_${index}`, index)}
                            >
                              {__(category.name)}
                            </IonButton>
                          );
                        })}
                    </div>
                  </>
                ) : (
                  <NoDataSecond label="No matching items found" />
                )}
              </div>
              <div className="order-content">
                <OrderList
                  scrollTopPosition={scrollTop}
                  selectCategoryOnScroll={this.selectCategoryOnScroll}
                  category={!isEmptyObject(ikentooMenu) ? ikentooMenu : null}
                  menuRefs={this.state.menuRefs ? this.state.menuRefs : []}
                />
                {Basket.itemsCount() > 0 ? (
                  <div className="view-order-button">
                    <IonButton
                      onClick={() => forwardTo('/order-summary')}
                      expand="block"
                      color="secondary"
                    >
                      <IonGrid>
                        <IonRow>
                          <IonCol>{__('View Order')}</IonCol>
                          <IonCol>
                            {Basket.itemsCountAll()}&nbsp;
                            {Basket.itemsCountAll() === 1 ? __('Item') : __('Items')}
                          </IonCol>
                          <IonCol>{Basket._getTotal()}</IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonButton>
                  </div>
                ) : null}
              </div>
            </div>
          )}
          <Modal
            cssClass="table-number-modal"
            isOpen={this.state.tableNumberModalOpen}
            onDidDismiss={() =>
              this.setState({
                tableNumberModalOpen: false,
                tableNumberValue: Basket.getTableNumber(),
              })
            }
          >
            <Subtitle className="centered">{__('Change Table Number')}</Subtitle>
            <Spacer size={1} />
            <IonItem>
              {selectedResturant && selectedResturant.length > 0 ? (
                <SelectOption
                  display="center"
                  label="Table Number"
                  inputStyle="box"
                  setText={__('OK')}
                  cancelText={__('Cancel')}
                  data={this.formatTableNumberForSelect(Basket.getRestaurant())}
                  value={this.state.tableNumberValue}
                  onSet={(e, a) => this.selectTableNumber(e, a)}
                  disabled={(this.state.totalTableNumbers || []).length <= 1 ? true : false}
                />
              ) : (
                <>
                  <IonInput
                    className="table-number-input"
                    onIonChange={(event) => {
                      this.setState({ tableNumberValue: event.target.value });
                    }}
                    value={this.state.tableNumberValue}
                    type="text"
                    placeholder={__('Enter table number')}
                  />
                </>
              )}
            </IonItem>
            <Spacer size={1} />
            <IonButton
              color="secondary"
              expand="block"
              className="uppercase"
              onClick={() => {
                this.tableNumberChanged();
              }}
            >
              {' '}
              {__('Confirm')}
            </IonButton>
          </Modal>
        </Layout>
        <Modal
          className="search-modal-wrapper"
          isOpen={this.state.searchModalOpen}
          onDidDismiss={() => this.setState({ searchModalOpen: false })}
        >
          <div className="search-box">
            <IonIcon icon={search}></IonIcon>
            <IonInput
              placeholder={__('Search products')}
              value={this.state.searchedValueModal}
              onIonChange={(e) => this.setState({ searchedValueModal: e.target.value })}
            />
          </div>
          <Spacer />
          <IonButton color="secondary" expand="block" onClick={() => this.getSearchedItems()}>
            {__('Search')}
          </IonButton>
        </Modal>
      </>
    );
  }
}

const stateToProps = (state) => {
  const { auth } = state.common;
  const {
    restaurants,
    ikentooMenu,
    defaultMenu,
    restaurantsUpdated,
    isPassedOrder,
  } = state.restaurants;
  const { scrollTop, deliveryOption } = state.orders;
  return {
    auth,
    restaurants: restaurants || [],
    ikentooMenu: ikentooMenu || {},
    defaultMenu: defaultMenu || {},
    basketUpdated: state.orders.basketUpdated,
    restaurantsUpdated,
    scrollTop,
    deliveryOption,
    isPassedOrder,
  };
};

export default connect(stateToProps)(withTranslation(OrderPage));
