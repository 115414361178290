import { IonButton } from '@ionic/react';
import React, { Component } from 'react';
import { withTranslation } from '../../lib/translate.js';
import { NormalText, Title } from '../common/index.js';
import plateImage from '../../assets/images/icons/eat.png'
class VoucherItem extends Component {
  render(){
    const { reward, id, className, __, action } = this.props;
    const image = reward.image;
    return (
      <div className={'voucher' + (className ? ' ' + className : '')}>
        <div className='voucher-card-wrapper' onClick={() => action(id)}>
          <div className='voucher-card-content'>
            <div className='voucher-title-wrapper'>
              <Title className='ellipsis'>{reward.name}</Title>
              <NormalText >{reward.small_print}</NormalText>
            </div>
            {!image &&<img src={plateImage} alt=' ' />}
          </div>
          {image ? <img src={image} alt=' ' />
            :(
              <svg xmlns="http://www.w3.org/2000/svg" width="265" height="134.291" viewBox="0 0 265 134.291">
                <g id="Group_2194" data-name="Group 2194" transform="translate(228 -3659)">
                  <g id="Group_1988" data-name="Group 1988" transform="translate(-303 3140)">
                    <g id="Group_1985" data-name="Group 1985" transform="translate(75 519)">
                      <path id="Path_5431" data-name="Path 5431" d="M340,532.429V519H75v13.491c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062V625.6c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v13.491H340V639.862a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,1,1,0-14.324V570.03a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,1,1,0-14.324Z" transform="translate(-75 -519)" />
                    </g>
                    <g id="Group_1987" data-name="Group 1987" transform="translate(75 519)">
                      <g id="Group_1986" data-name="Group 1986" opacity="0.1">
                        <path id="Path_5432" data-name="Path 5432" d="M340,532.429V519H75v13.491c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v9.076c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062V625.6c.9-.037.816-.062,1.119-.062a7.164,7.164,0,1,1-.056,14.324c-.3,0-.168-.025-1.064-.062v13.491H340V639.862a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,1,1,0-14.324V570.03a7.162,7.162,0,0,1,0-14.324v-8.953a7.162,7.162,0,1,1,0-14.324Z" transform="translate(-75 -519)" />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            )
          }
          
        </div>
      </div>
    )
  }
    
}
export default withTranslation(VoucherItem);