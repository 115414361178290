import React from 'react';
import {
  IonButton, IonItem, IonInput, /*, isPlatform */
  IonText
} from '@ionic/react';
import { connect } from 'react-redux';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import Clickup from '../../components/clickup';
import Modal from '../../components/modal';
import { Title, Spacer, NormalText, SmallText, FieldError, StrongText } from '../../components/common';
import { validateForm, isDefined } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import { getRewards, sendVoucherCode } from '../../store/actions';
import { forwardTo } from '../../lib/utils';
import QRCodeCanvas from 'qrcode.react';
import moment from '../../lib/moment';
import './index.css';
import NoDataSecond from '../../components/noDataSecond';
import VoucherItem from '../../components/voucherItem';

class Vouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherCode: '',
      selectedVoucher: null,
      formErrors: {
        voucherCode: '',
      },
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    this.props.dispatch(getRewards());
    if (
      isDefined(this.props.location.state) &&
      isDefined(this.props.location.state.openVoucherModal)
    ) {
      setTimeout(() => {
        this.viewVoucher(this.props.location.state.openVoucherModal);
      }, 500);
    }
  }

  viewVoucher = (id) => {
    const { vouchers } = this.props;
    this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) });
  };

  redeemVoucher = () => {
    const { voucherCode } = this.state;
    if (voucherCode !== '') {
      //console.log('Redeem voucher, code: "' + this.state.voucherCode + '"')
    }
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      dispatch(sendVoucherCode(data));
    }
  };

  handlerOfVoucherCode = (e) => {
    const val = e.target.value.toUpperCase();
    this.setState({ voucherCode: val });
  };

  render() {
    const { __, vouchers, qr_code,rewards,profile} = this.props;
    const { voucherCode, selectedVoucher } = this.state;
    let voucherRes = (vouchers || []).filter((data) => {
      if (data.type === 1) {
        return data;
      }
      return ''
    });
    const available_balance = profile.available_balance;
    let rewardsFromPoints = [];
    if(rewards.length === 1) {
      let n = 1
      while(n<=available_balance/rewards[0].stamps_required){
        rewardsFromPoints.push(rewards[0])
        n++
      }
    }
    const clickupContent = (
      <Clickup label={__('Redeem Offer Code')} height="320">
        <div className="absolute-wrapper flex-row-wrapper">
          <div className="padded no-padding-bottom centered scrollable-y">
            <p className="no-margin-top">
              {__('Enter a valid voucher code below to receive your reward')}
            </p>
            <div className="lefted no-padding-bottom voucher-item">
              <IonItem style={{ '--min-height': '32px' }}>
                <div className="inline-input">
                  <IonText class='bold'>{__('Offer Code')}</IonText>
                  <IonInput
                    value={voucherCode}
                    onIonChange={(e) => this.handlerOfVoucherCode(e)}
                    placeholder={__('Enter your offer code')}
                  />
                </div>
                <FieldError className="field-error" value={__(this.state.formErrors.voucherCode)} />
              </IonItem>
            </div>
          </div>
          <div className="padded no-padding-top flex-min">
            <IonButton
              disabled={voucherCode === ''}
              color="secondary"
              expand="block"
              onClick={this.sendCode}
            >
              {__('Submit')}
            </IonButton>
          </div>
        </div>
      </Clickup>
    );
    return (
      <Loading transparent>
        <Layout hideSecondToolbar={true} headerTitle={__('Vouchers')} scrollY={false}>
          <div className="absolute-content flex-row-wrapper">
            <div className="flex-min ion-text-center">
              <Title className='centered'>{__('Voucher Wallet')}</Title>
              <Spacer size={1} />
            </div>
            <div className="scrollable-y">
              {!voucherRes || voucherRes.length === 0 ? (
                <NoDataSecond label="Your vouchers will appear here" />
              ) : null}
              {voucherRes &&
                voucherRes.map((voucher, index) => {
                  return (
                    <VoucherItem
                      key={'vc-' + index}
                      {...voucher}
                      __={__}
                      action={this.viewVoucher}
                    />
                  );
                })}
                {rewardsFromPoints &&
                rewardsFromPoints.map((reward, index) => {
                  return (
                    <VoucherItem
                      key={'vc-' + index}
                      {...reward}
                      __={__}
                      action={this.viewVoucher}
                    />
                  );
                })}
              <Spacer size={2} />
            </div>
          </div>
          <div className="flex-min">{clickupContent}</div>
        </Layout>
        <Modal
          className="voucher-modal"
          isOpen={selectedVoucher !== null}
          onDidDismiss={() => this.setState({ selectedVoucher: null })}
          title={__('Voucher Info')}
        >
          {selectedVoucher && selectedVoucher.reward ? (
            <div className="centered">

              <VoucherItem
                {...selectedVoucher}
                __={__}
              />

              <Spacer size={2} />
              <div className="voucher-modal-content">
                {selectedVoucher.reward.name ? (
                  <>
                    <Title className="centered uppercase bold no-margin-bottom">{selectedVoucher.reward.name}</Title>
                  </>
                ) : null}
                {selectedVoucher.reward.small_print ? (
                  <div>
                    <NormalText>{selectedVoucher.reward.small_print}</NormalText>
                  </div>
                ) : null}
                {selectedVoucher.reward.expiry_date ? (
                  <div>
                    <NormalText>
                      {__('Expires:')}{' '}
                      {moment(selectedVoucher.reward.expiry_date, 'YYYY/MM/DD').format('DD/MM/YY')}
                    </NormalText>
                  </div>
                ) : null}
                <Spacer size={1} />
                <div onClick={() => forwardTo('/terms')}>
                  <StrongText className="bold">{__('Terms & Conditions')}</StrongText>
                </div>
                {selectedVoucher.reward.description ? (
                  <div>
                    <SmallText>{__(selectedVoucher.reward.description)}</SmallText>
                  </div>
                ) : null}
                <>
                  {isDefined(qr_code) ? (
                    <div className="qr-holder">
                      <QRCodeCanvas value={qr_code} size={150} />
                    </div>
                  ) : (
                    <div className="noQrCode">
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                </>
              </div>
            </div>
          ) : null}
        </Modal>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { rewards } = state.restaurants;
  const { profile } = state.profile;
  return {
    vouchers: state.profile.vouchers || [],
    qr_code: state.profile.profile.qr_code,
    rewards: rewards || [],
    profile
  };
};

export default connect(stateToProps)(withTranslation(Vouchers));
