import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import SwipableTabs from '../../components/swipeableTabs';
import './index.css';
import { ApplyPoints } from '../applyPoints';
import ApplyVouchers from '../applyVouchers';




class ApplyLoyalty extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { __, } = this.props;

        return (
            <Layout
            hideSecondToolbar={true}
                headerTitle={__('History')}
                color="transparent"
                scrollY={false}
            >
                <SwipableTabs
                    defaultTab={this.state.defaultTab}
                    history={this.props.location}
                    tabs={[
                        {
                            label: __('POINTS'),
                            tabContent: (
                                <ApplyPoints />
                            ),
                        },
                        {
                            label: __('VOUCHERS'),
                            tabContent: (
                               <ApplyVouchers />
                            ),
                        },
                    ]}
                />
                
            </Layout>
        );
        
    }
}

const stateToProps = (state) => {
    return {
        transactionHistory: state.orders.history || [],
        orderHistory: state.orders.orderHistory || [],
    };
};

export default withRouter(withTranslation(connect(stateToProps)(ApplyLoyalty)));
