import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IonButton, IonCheckbox, IonRadioGroup, IonRadio, IonLabel, IonItem, IonIcon, IonInput } from '@ionic/react';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import Modal from '../../components/modal';
import { SmallText, Sectiontitle, Title, NormalText } from '../../components/common';
import { isDefined, isEmptyObject, makeKey, deepCopy, forwardTo, goBack } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import { setScrollTop } from '../../store/actions';
import { OrderSummary } from '../orderSummary';
import { ItemDetailsRaw } from '../itemDetails';
import OrderList from './orderList';
import './index.css';
import { search } from 'ionicons/icons';
import NoDataSecond from '../../components/noDataSecond';

const {
  validateItem,
  getProductName,
  getProductDescription,
  _calculateItemPrice,
  formatPrice,
  addToBasket,
  isChoicesGroupValid,
} = Basket;

const defaultModalItemOptions = {
  quantity: 1,
  price: 0,
  selectedChoices: [],
  instructions: '',
};

class OrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: 0,
      ikentooMenu: null,
      menuRefs: null,
      categoriesPositionTop: [],
      modalOpen: false,
      modalItem: null,
      modalItemOptions: defaultModalItemOptions,
      validationErrors: [],
      showValidationAlert: false,
      preventScroll: false,
      nameVisible: true,
      searchItemsValue: '',
      originalMenu: null
    };

    this.selectCategoryOnScroll = this.selectCategoryOnScroll.bind(this);
  }
  onContentScrollHandler() {
    const nameVisible = this.isScrolledIntoView(document.querySelector('.item-details-modal-name'));
    if (this.state.nameVisible !== nameVisible) {
      if (!nameVisible) {
        this.setState({ nameVisible: false });
      } else {
        this.setState({ nameVisible: true });
      }
    }
  }
  isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top - 90;
    var elemBottom = rect.bottom;
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    return isVisible;
  }
  setIkentooMenu = (menu) => {
    this.setState(
      {
        ikentooMenu:
          menu || (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu))
            ? this.props.defaultMenu
            : this.props.ikentooMenu,
        originalMenu:
          menu || (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu))
            ? this.props.defaultMenu
            : this.props.ikentooMenu,
      },
      () => {
        let menuRefs = this.menuRefs(this.state.ikentooMenu);
        this.setState({ menuRefs });
      },
    );
  };

  componentDidMount() {
    this.setIkentooMenu();
  }

  shouldComponentUpdate(nextProps) {
    const prevMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
      ? nextProps.defaultMenu
      : nextProps.ikentooMenu || {}
    ).menuName;
    const currentMenuName = (Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
      ? this.props.defaultMenu
      : this.props.ikentooMenu || {}
    ).menuName;
    if (
      prevMenuName !== currentMenuName ||
      nextProps.restaurantsUpdated !== this.props.restaurantsUpdated
    ) {
      this.setIkentooMenu(
        Basket.items.length === 0 && isEmptyObject(this.props.ikentooMenu)
          ? nextProps.defaultMenu
          : nextProps.ikentooMenu,
      );
    }
    return true;
  }

  menuRefs = (menuRefs) => {
    return menuRefs && menuRefs.menuEntryGroups
      ? menuRefs.menuEntryGroups.reduce((acc, value, index) => {
        let name = `${value.name}_${index}`;
        acc[name] = React.createRef();
        return acc;
      }, {})
      : {};
  };

  scrollTo = (name, index) => {
    this.state.menuRefs[name].current.scrollIntoView();
    this.setState({
      selectedCategory: index,
      preventScroll: true,
    });
  };

  selectCategoryOnScroll = (e) => {
    e.preventDefault();
    if (!this.state.preventScroll) {
      const positionTop =
        e.target.scrollTop + document.querySelector('.order-categories').clientHeight;
      const { menuRefs } = this.state;
      const positions = Object.keys(menuRefs).map((key) => {
        return menuRefs[key].current.offsetTop;
      });
      let selectCategory = 0;
      let nums = [...positions];
      nums = nums.sort((a, b) => Math.abs(positionTop - a) - Math.abs(positionTop - b));
      let closest = nums[0];
      positions.forEach((item, i) => {
        if (item === closest) {
          selectCategory = i;
        }
      });
      if (document) {
        const catDiv = document.querySelector('.order-categories-inner');
        if (catDiv && catDiv.childNodes && catDiv.childNodes[selectCategory]) {
          setTimeout(() => {
            catDiv.childNodes[selectCategory].scrollIntoView({
              behavior: 'smooth',
              inline: 'start',
            });
          }, 500);
        }
      }
      this.props.dispatch(setScrollTop(e.target.scrollTop));
      this.setState({ selectedCategory: selectCategory, scrollTop: e.target.scrollTop });
    }
    this.setState({ preventScroll: false });
  };

  showModal = (modalItem) => {
    this.setState({
      modalItem,
      modalItemOptions: {
        ...this.state.modalItemOptions,
        price: _calculateItemPrice({ item: modalItem, quantity: 1 }),
        selectedChoices:
          modalItem && modalItem.menuDealGroups && modalItem.menuDealGroups.length
            ? Array(modalItem.menuDealGroups.length).fill([])
            : [],
        validationErrors:
          modalItem && modalItem.menuDealGroups && modalItem.menuDealGroups.length
            ? Array(modalItem.menuDealGroups.length).fill(null)
            : [],
      },
      modalOpen: true,
    });
  };

  handleInputChange = (groupIndex, choiceIndex, multiSelectionPermitted, event) => {
    const item = this.state.modalItem;
    const { selectedChoices, quantity } = this.state.modalItemOptions;
    const allGroups = item.menuDealGroups ? item.menuDealGroups : [];
    const selectedGroup = allGroups[groupIndex];
    if (selectedGroup) {
      const selectedChoice = selectedGroup.items[choiceIndex];
      let updatedSelectedChoices = deepCopy(selectedChoices);
      if (multiSelectionPermitted) {
        //checkbox
        if (event.target.checked) {
          updatedSelectedChoices[groupIndex].push(selectedChoice);
        } else {
          updatedSelectedChoices[groupIndex] = updatedSelectedChoices[groupIndex].filter(
            (i) => i.sku !== selectedChoice.sku,
          );
        }
      } else {
        //radio
        if (!selectedChoice) {
          updatedSelectedChoices[groupIndex] = [];
        } else {
          updatedSelectedChoices[groupIndex] = [selectedChoice];
        }
      }

      this.setState(
        {
          modalItemOptions: {
            ...this.state.modalItemOptions,
            selectedChoices: updatedSelectedChoices,
          },
        },
        () => {
          const { selectedChoices } = this.state.modalItemOptions;
          //recalculate item price on every menu deal choice change
          this.setState({ price: _calculateItemPrice({ item, quantity, selectedChoices }) }, () => {
            const validationErrors = validateItem(this.constructBasketItem());
            this.setState({ validationErrors: validationErrors.errors });
          });
        },
      );
    }
  };

  drawGroupChoices = (choices = [], multiSelectionPermitted, groupIndex) => {
    const { selectedChoices } = this.state.modalItemOptions;
    const { profile, __ } = this.props;
    const allChoices = choices.map((item, choiceIndex) => {
      const { sku, productPrice } = item;
      const isChecked = !!(selectedChoices[groupIndex] || []).find((i) => i.sku === sku);
      return (
        <IonItem lines="none" key={makeKey(choiceIndex, sku, groupIndex)}>
          <div tabIndex="-1"></div>
          {multiSelectionPermitted ? (
            <IonCheckbox
              color="primary"
              slot="start"
              checked={isChecked}
              onIonChange={(event) => {
                this.handleInputChange(groupIndex, choiceIndex, multiSelectionPermitted, event);
              }}
            />
          ) : (
            <IonRadio
              slot="start"
              className="details-radio"
              color="contrast"
              value={choiceIndex}
              checked={isChecked}
            />
          )}
          <IonLabel className="ion-text-wrap">
            <Sectiontitle className="single-item">{__(getProductName(item, profile))}</Sectiontitle>
            {getProductDescription(item, profile) ? (
              <SmallText className="no-margin">{getProductDescription(item, profile)}</SmallText>
            ) : null}
          </IonLabel>
          <p>{formatPrice(productPrice)}</p>
        </IonItem>
      );
    });
    if (multiSelectionPermitted) {
      return allChoices;
    } else {
      //radio
      return (
        <IonRadioGroup
          onIonChange={(event) => {
            this.handleInputChange(groupIndex, event.target.value, multiSelectionPermitted, event);
          }}
        >
          {allChoices}
        </IonRadioGroup>
      );
    }
  };

  drawGroupLabel = (label, groupIndex) => {
    const { validationErrors } = this.state;
    const { __ } = this.props;

    return (
      <>
        <IonItem>
          <div tabIndex="-1" className="sectiontitle">
            {label}
          </div>
        </IonItem>
        {validationErrors[groupIndex] ? (
          <div className="field-error">{__(validationErrors[groupIndex])}</div>
        ) : null}
      </>
    );
  };

  drawMenuDealGroups = (menuGroupItem, index) => {
    // multiSelectionPermitted = true  --> only one item must be selected
    const multiSelectionPermitted = menuGroupItem.multiSelectionPermitted;
    if (isChoicesGroupValid(menuGroupItem)) {
      if (isDefined(multiSelectionPermitted)) {
        return (
          <div key={index}>
            {this.drawGroupLabel(menuGroupItem.description, index)}
            {this.drawGroupChoices(menuGroupItem.items, multiSelectionPermitted, index)}
          </div>
        );
      } else {
        return this.drawGroupLabel(menuGroupItem.description);
      }
    }
  };

  constructBasketItem = () => {
    const { modalItem, modalItemOptions } = this.state;
    const { quantity, selectedChoices, instructions } = modalItemOptions;
    let constructModel = {};
    if (modalItem) {
      constructModel = {
        item: modalItem,
        quantity,
        selectedChoices,
        instructions,
      };
    }
    return constructModel;
  };

  onIncrementerUpdate = (newQuantity) => {
    const price = _calculateItemPrice({
      item: this.state.modalItem,
      quantity: newQuantity,
      selectedChoices: this.state.modalItemOptions.selectedChoices,
    });
    this.setState({
      modalItemOptions: { ...this.state.modalItemOptions, quantity: newQuantity, price },
    });
  };

  addToOrder = () => {
    const newBasketItem = this.constructBasketItem();
    const validationErrors = validateItem(newBasketItem);
    if (validationErrors.errorCount > 0) {
      this.setState({ validationErrors: validationErrors.errors });
      this.setState({ validationErrors: validationErrors.errors }, () => {
        this.setShowValidationAlert(true);
      });
    } else {
      addToBasket(newBasketItem);
      this.setState({
        modalItem: null,
        modalItemOptions: defaultModalItemOptions,
        modalOpen: false,
      });
      /*forwardTo('/order-summary')
      forwardTo('/order-summary', { fromItemDetails: true })*/
    }
  };

  instructionsChange = (event) => this.setState({ instructions: event.target.value });

  closeModal = () =>
    this.setState({
      modalOpen: false,
      modalItemOptions: defaultModalItemOptions,
      validationErrors: [],
    });

  backHandler = () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.skipBackToThePreviousPage
    ) {
      forwardTo('/delivery-options');
    } else {
      goBack();
    }
  };
  filterMenu(menu) {
    if (menu && menu.menuEntryGroups) {
      menu.menuEntryGroups = this.filterIkentooMenuItems(menu.menuEntryGroups);
      return menu;
    }
    return menu;
  };
  filterIkentooMenuItems(items) {
    let filteredItems = [];
    items.forEach((item) => {
      if (!item.menuEntry) {
        // break recursion when arrive to the product
        if (item.productName.toLowerCase().includes(`${this.state.searchItemsValue}`)) {
          filteredItems.push(item);
          return [item];
        } else {
          return [];
        }
      } else {
        const len = (item.menuEntry || []).length;
        if (len > 0) {
          const newFilteredItems = this.filterIkentooMenuItems(item.menuEntry);
          if (newFilteredItems.length > 0) {
            item.menuEntry = newFilteredItems;
            filteredItems.push(item);
          }
        }
      }
    });

    return filteredItems;
  };
  filterMenuItemsTest = (e) => {
    this.setState({ searchItemsValue: e.target.value }, () => {
      const menu = JSON.parse(JSON.stringify(this.state.originalMenu))
      const filtered = this.filterMenu(menu)
      this.setState({ ikentooMenu: filtered }, () => {
        let menuRefs = this.menuRefs(this.state.ikentooMenu);
        this.setState({ menuRefs });
      })
    })
  }
  render() {
    const { __, scrollTop, profile } = this.props;
    const { selectedCategory, ikentooMenu, modalOpen, modalItem } = this.state;
    let categories = !isEmptyObject(ikentooMenu) ? ikentooMenu.menuEntryGroups : [];
    let modalItemImage =
      modalItem && modalItem.itemRichData && modalItem.itemRichData.squareImageUrl
        ? modalItem.itemRichData.squareImageUrl
        : '';
    if (modalItemImage && modalItemImage.indexOf('http://') !== -1) {
      modalItemImage = modalItemImage.replace(/http:\/\//g, 'https://');
    }
    return (
      <Loading>
        <Layout noPadding backHandler={this.backHandler} scrollY={false}>
          {!isDefined(ikentooMenu) && !isEmptyObject(ikentooMenu) && !ikentooMenu ? null : (
            <div className={'order-list' + (Basket.items.length < 1 ? ' order-list-full' : '')}>
              <div className="order-categories">
                <div className='search-box-wrapper'>
                  <Title style={{ margin: 0 }}>{__('Select Items')}</Title>
                  <div className='search-box'>
                    <IonIcon color='secondary' icon={search}></IonIcon>
                    <IonInput placeholder={__('Search products')} value={this.state.searchItemsValue} onIonChange={this.filterMenuItemsTest} />
                  </div>
                </div>                
                <div className="order-categories-inner">
                {categories.length > 0 ?
                  <>
                  {categories &&
                    <>
                      {categories.filter(el => el.menuEntry.length > 0).map((category, index) => {
                        return (
                          <IonButton
                            key={index}
                            size="small"
                            fill="clear"
                            className={
                              selectedCategory === index ? 'category-button active' : 'category-button'
                            }
                            onClick={() => this.scrollTo(`${category.name}_${index}`, index)}
                          >
                            {__(category.name)}
                          </IonButton>
                        );
                      })}
                    </>
                  }
                    </>
                  : <NoDataSecond className='no-searched-products-message' label="No matching items found" />}
                </div>
              </div>
              <div className="order-content">
                <OrderList
                  showModal={this.showModal}
                  scrollTopPosition={scrollTop}
                  selectCategoryOnScroll={this.selectCategoryOnScroll}
                  category={!isEmptyObject(ikentooMenu) ? ikentooMenu : null}
                  menuRefs={this.state.menuRefs ? this.state.menuRefs : []}
                />
              </div>
            </div>
          )}
          {Basket.items.length > 0 ? (
            <div className="order-summary-sidebar">
              <OrderSummary />
            </div>
          ) : null}
        </Layout>
        {modalOpen &&
          <Modal
            className="item-details-modal"
            isOpen={modalOpen}
            onDidDismiss={() => this.closeModal()}
          >
            {modalItem ? (
              <>
                <div className="item-details-card">
                  <div
                    onScroll={() => this.onContentScrollHandler()}
                    className={'item-details-content'}
                  >
                    <div
                      className={
                        this.state.nameVisible
                          ? 'item-details-model-header'
                          : 'item-details-model-header active'
                      }
                    >
                      <NormalText>
                        <strong>{__(getProductName(modalItem, profile))}</strong>
                      </NormalText>
                    </div>
                    <div className="item-details-modal-info">
                      {modalItemImage && modalItemImage !== '' ? (
                        <img src={modalItemImage} alt='' />
                      ) : (
                        <div className="image-placeholder"></div>
                      )}
                      <div>
                        <Title className="okx-font-secondary item-details-modal-name">
                          {__(getProductName(modalItem, profile))}
                        </Title>
                        <div className="item-details-card-price okx-font-secondary">
                          {formatPrice(modalItem.productPrice)}
                        </div>
                      </div>
                    </div>
                    <ItemDetailsRaw
                      item={modalItem}
                      profile={profile}
                      modalOpen={modalOpen}
                      closeModal={this.closeModal}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </Modal>
        }
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth } = state.common;
  const { restaurants, ikentooMenu, defaultMenu, restaurantsUpdated } = state.restaurants;
  const { scrollTop } = state.orders;
  return {
    auth,
    restaurants: restaurants || [],
    ikentooMenu: ikentooMenu || {},
    defaultMenu: defaultMenu || {},
    basketUpdated: state.orders.basketUpdated,
    restaurantsUpdated,
    scrollTop,
  };
};

export default connect(stateToProps)(withTranslation(OrderPage));
