import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import QRCodeCanvas from 'qrcode.react';
import Basket from '../../lib/basket';
import moment from 'moment';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { getRewards, getTransactionHistory } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import './index.css';
import { isDefined, isEmptyObject } from '../../lib/utils';
import UnlockRewardItems from '../../components/unlockRewardItems';

import {
  NormalText,
  SmallText,
  StrongText,
  Subtitle
} from '../../components/common';
import { IonCol, IonRow } from '@ionic/react';
import { getConfig } from '../../appConfig';
import NoDataSecond from '../../components/noDataSecond';
class Loyalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: null,
      loyaltyType: ''
    };
  }

  componentDidMount() {
    this.props.dispatch(getRewards());
    this.props.dispatch(getTransactionHistory());
    const loyaltyType = getConfig().appType.hasUnlockRewardsOnLoyalty ? 'rewards' : 'points';
    this.setState({ loyaltyType })
    if (this.props.location && this.props.location.state && this.props.location.state.tab) {
      this.setState({ page: this.props.location.state.tab });
    }
  }
  getLabel(item) {
    const { __ } = this.props;
    let label = ''
    if (`${item.business_location_id}` === '-1') {
      label = __('Referral Bonus')
    }
    else if (`${item.business_location_id}` === '-2') {
      label = __('Sign up Bonus')
    }
    else if (`${item.business_location_id}` === '-3') {
      label = __('Refunded points')
    }
    else if (item.stamp_power < 0) {
      label = __('Redeemed Points')
    }
    else if (item.stamp_power > 0) {
      label = __('Earned Points')

    }
    return label
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.tab &&
      prevProps.location &&
      prevProps.location.state &&
      prevProps.location.state.tab &&
      prevProps.location.state.tab !== this.props.location.state.tab
    ) {
      this.setState({ page: this.props.location.state.tab });
    }
  }
  drawLoyaltyContent(profile, rewards, __, qr_code) {
    const { loyaltyType } = this.state;
    if (loyaltyType === 'points') {
      return (
        <div className="loyalty-content">
          <div className="loyalty-content-desc">
            <NormalText>{__('Collect points every time you scan.')} {__('Earn')} {getConfig()?.api_config?.points_value_percentage} {__('points for every £ spend')}</NormalText>
          </div>
        </div>
      )
    }
    else if (loyaltyType === 'rewards') {
      return (
        <div className="loyalty-content">
          <NormalText className="bold">{__('AVAILABLE REWARDS')}</NormalText>
          <div className='loyalty-rewards-wrapper'>
            <UnlockRewardItems
              available_balance={profile.available_balance}
              rewards={rewards}
              qr_code={qr_code}
              __={__}
            />

          </div>

          {/* <div className='loyalty-rewards-wrapper'>
            {rewards.map(item => {
              return (
                <div className='loyalty-reward' style={{backgroundImage:`url(${item.reward.image})`}}>
                  {item.reward.cost>0 && <div className='loyalty-reward-points'><StrongText>{item.reward.cost} Points</StrongText></div>}
                  <div  className='loyalty-reward-name'><StrongText>{item.reward.name}</StrongText></div>
                </div>
              )
            })}
            </div> */}
        </div>
      )
    }
  }
  render() {
    const { __, rewards, profile, screenName, transactionHistory } = this.props;
    return (
      <Loading>
        <Layout
          color="transparent"
          hideSecondToolbar={true}
          headerTitle={__('Loyalty')}
          noPadding={true}
        >
          <div className='scrollable-y'>
            <div className="loyalty-header">
              <div className="loyalty-header-name">
                <Subtitle className="loyalty-name" color="white">
                  {screenName}
                </Subtitle>
              </div>
              <div className="loyalty-header-content">
                <div>
                  <div>
                    <Subtitle className="loyalty-points">{profile.available_balance.toLocaleString()} {__('pts')}</Subtitle>
                    <NormalText className="">
                      {Basket._calculatePointsAppliedPrice(profile.available_balance, false, true)}
                    </NormalText>
                  </div>
                  <div>
                    <Subtitle className="uppercase">{__('Member since')}</Subtitle>
                    <NormalText className="uppercase ">{moment(profile.created_at).format('MM/YY')}</NormalText>
                  </div>
                </div>
                <div className="qr-code">
                  {isDefined(profile.qr_code) ? (
                    <div className="qr-holder">
                      <QRCodeCanvas value={profile.qr_code} size={120} />
                    </div>
                  ) : (
                    <div>
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {this.drawLoyaltyContent(profile, rewards, __, profile.qr_code)}

            <div className="loyalty-history">
              {
                transactionHistory.length > 0 ?
                <>
                  <NormalText>{__('Latest Transactions')}</NormalText>
                  <div className="transactions-wrapper">
                    {transactionHistory.map((el,index) => {
                      return (
                        <div className="content-box small-padding" key={index}>
                          <IonRow>
                            <IonCol>
                              <SmallText >{el.location_name}</SmallText>
                            </IonCol>
                            <IonCol style={{ textAlign: 'end' }}>
                              <StrongText color="border">
                                {el.stamp_power < 0 ? '-' : '+'} {Math.abs(el.stamp_power)} {__('pts')}
                              </StrongText>
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol>
                              <StrongText>
                                {__(this.getLabel(el))}
                              </StrongText>
                            </IonCol>
                            <IonCol style={{ textAlign: 'end' }}>
                              <NormalText >
                                {' '}
                                {Basket.getDate(el.transaction_date).format('DD MMM YYYY h:mm a')}
                              </NormalText>
                            </IonCol>
                          </IonRow>
                        </div>
                      );
                    })}
                  </div>
                </> : <NoDataSecond label={'No Transaction'}/>
                
              }
              
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { rewards } = state.restaurants;
  const { history } = state.orders;
  let screenName = '';
  if (
    (isDefined(profile) && !isEmptyObject(profile) && profile.first_name) ||
    profile.last_name ||
    (profile.first_name && profile.last_name)
  ) {
    screenName = `${profile.first_name} ${profile.last_name}`;
  }
  return {
    profile,
    rewards: rewards || [],
    screenName,
    transactionHistory: history || [],
  };
};

export default connect(stateToProps)(withRouter(withTranslation(Loyalty)));
