import React from 'react';
import { IonItem, IonInput, IonButton, IonIcon, IonLabel } from '@ionic/react';
import { connect } from 'react-redux';
import { lockClosed, mail } from 'ionicons/icons';
import { getConfig } from '../../appConfig';
import Layout from '../../components/layout';
import PasswordInput from '../../components/passwordInput';
import { forwardTo, getDefaultRoute } from '../../lib/utils';
import { loginRequest } from '../../store/actions';
import { withTranslation } from '../../lib/translate';
import Loading from '../../components/spinner';
import { FieldError, Title, Spacer } from '../../components/common';
import { validateForm } from '../../lib/utils';
import './index.css';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      formErrors: {},
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    this.formConfig = {
      username: { type: 'email', required: true },
      password: { type: 'password', required: true },
    };
  }

  handleInput(key, val) {
    this.setState({ [key]: val });
  }

  handleLogin() {
    let formErrors = validateForm(this.formConfig, this.state);
 
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      let referrer;
      if(this.props?.location?.state?.backToOrder){
        referrer = '/order'
      }
      else{
        referrer = this.props.protectedReferrer
      }
      const { username, password } = this.state;
      this.props.dispatch(loginRequest({ username, password, referrer }));
    }
  }

  checkLoginStatus = () => {
    const { loggedIn } = this.props.auth;
    const {navConfig} = this.props
    if (loggedIn) {
      const defaultRoute = getDefaultRoute(navConfig);
      forwardTo(defaultRoute.path);
    }
  };

  componentDidUpdate() {
    this.checkLoginStatus();
  }

  componentDidMount() {
    this.checkLoginStatus();
  }

	render () {
		const { __ } = this.props
		const { username, password } = this.state
		return (
			<Loading transparent>
				<Layout hideSecondToolbar={true} headerTitle={ __('Login') } hideBack={true} color="transparent" contentClassName="login-background">
					<div className="absolute-content flex-row-wrapper">
						<div className="flex-min">
							{ getConfig().theme.showHeaderOnAuthRoutes ?
								<>
									<Title className="uppercase">{__('Welcome back')}! {/* __('You\'ve been missed') */}</Title>
									<Spacer size={3} />
								</>
								:
								<h3 className="login-header">{ __('Click below to register or login') }</h3>
							}
						</div>
						<div>
							<IonItem>
								{ getConfig().theme.showInputIconsOnLogin ?
									<IonIcon slot="start" className="login-icon" size="small" icon={ mail } />
									: null }
                <IonLabel position='stacked'>{ __('Email address')} *</IonLabel>
								<IonInput  
                  onIonChange={ e => this.handleInput('username', e.target.value) } 
                  onIonBlur={(e) => {
                    const usernameInput = document.querySelector(
                      'input[type="email"]:-webkit-autofill',
                    );
                    if (usernameInput) {
                      this.handleInput('username', usernameInput.value);
                    }
                    this.handleInput('username', e.target.value);
                  }}
                  clearInput 
                  type="email" 
                  pattern="email" 
                  inputmode="email" 
                  value={ username } 
                  placeholder={__('Enter your email address')}
                />
							</IonItem>
							<FieldError className="field-error" value={ __(this.state.formErrors.username) } />
							<IonItem>
								{ getConfig().theme.showInputIconsOnLogin ?
									<IonIcon slot="start" className="login-icon" size="small" icon={ lockClosed }/>
									: null }
                                  <IonLabel position='stacked'>{ __('Password') } *</IonLabel>

								<PasswordInput  __={ __ } onIonChange={ e => this.handleInput('password', e.target.value) } value={ password } placeholder={__('Enter password')}/>
							</IonItem>
							<FieldError className="field-error" value={ __(this.state.formErrors.password) } />
							<Spacer size={ 2 }/>
							<IonButton expand="block" color="secondary" className="uppercase" onClick={() => this.handleLogin()}>{ __('Sign In') }</IonButton>
							<IonButton expand="block" fill="clear" color="secondary" className="capitalized underlined transparent" onClick={ () => forwardTo('/reset-password') }>{ __('Forgot password') }</IonButton>
						</div>
						<div className="flex-min">
							<IonButton expand="block"  color="primary" className="uppercase " onClick={() => forwardTo('/register')}><span><span>{ __('New User') }? </span> <span className='uppercase'>{ __('Create An Account') }</span></span></IonButton>
            </div>
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, protectedReferrer } = state.profile;
  const { navConfig } = state.common;
  return {
    auth,
    protectedReferrer,
    navConfig,
  };
};

export default connect(stateToProps)(withTranslation(Login));
