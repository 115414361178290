/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { IonButton, IonItem, IonInput } from '@ionic/react';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import { deepIsDefined, forwardTo, goBack, isDefined, validateForm } from '../../lib/utils';
import { sendVoucherCode, checkDiscount, showToast, getRewards } from '../../store/actions';
import {
  Title, Spacer, FieldError, /*, NormalText, SmallText*/
  NormalText,
  StrongText,
  SmallText
} from '../../components/common';
import Basket from '../../lib/basket';
import Modal from '../../components/modal';
import { getConfig } from '../../appConfig';
import './index.css';
import VoucherItem from '../../components/voucherItem';
import moment from 'moment';
import QRCodeCanvas from 'qrcode.react';
import Layout from '../../components/layout';

class ApplyVouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discounts: null,
      selectedVoucher: null,
      showModal: null,
      voucherCode: '',
      disabledAppliedButton: false,
      formErrors: {
        voucherCode: '',
      },
      selectedVoucherModal: null,
    };
    this.formConfig = {
      voucherCode: { type: 'text', required: true },
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(checkDiscount(Basket.parseBasketForCheckVouchers()));
    dispatch(getRewards());
  }


  setVoucher = (voucher) => {
    this.setState({ selectedVoucher: voucher }, () => {
      const { vouchers, applicableVouchers } = this.props;
      const { selectedVoucher } = this.state;
      const appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
      const applicableVoucher = applicableVouchers.find(
        (appliableVoucher) => appliableVoucher.id === selectedVoucher.id,
      );
      if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
        this.setState({ disabledAppliedButton: true });
      } else {
        this.setState({ disabledAppliedButton: false });
      }
    });
  };

  addVoucher = () => {
    const { selectedVoucher } = this.state;
    const { vouchers, applicableVouchers, dispatch, __ } = this.props;
    const appliedVoucher = vouchers.find((voucher) => voucher.id === selectedVoucher.id);
    const applicableVoucher = applicableVouchers.find(
      (appliableVoucher) => appliableVoucher.id === selectedVoucher.id,
    );
    if (Basket.canVoucherBeApplied(appliedVoucher, applicableVoucher)) {
      this.setState({ selectedVoucher: null }, () => {
        Basket.addVoucher(appliedVoucher, applicableVoucher);
        goBack();
      });
    } else {
      dispatch(
        showToast(
          __('Your basket is higher then voucher cost. Please add more items in basket'),
          'warning',
        ),
      );
    }
  };

  showModal = (show) => {
    this.setState({ showModal: show });
    this.clearFields();
  };

  clearFields = () => {
    this.setState({ voucherCode: '' });
  };

  sendCode = () => {
    const { dispatch, __ } = this.props;
    let formErrors = validateForm(this.formConfig, this.state, __);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const { voucherCode } = this.state;
      const data = { code: voucherCode.toUpperCase() };
      this.clearFields();
      dispatch(sendVoucherCode(data));
      this.showModal(false);
    }
  };

  filterVouchers = (vouchers, appliedVouchers) => {
    const { applicableVouchers, availableBalance } = this.props;
    let filteredVouchers = [];
    if (applicableVouchers && applicableVouchers.length > 0) {
      applicableVouchers.forEach((discount) => {
        let findedVoucher = vouchers.find((voucher) => voucher.id === discount.id);
        if (getConfig().vouchersType === 2) {
          if (findedVoucher && availableBalance > findedVoucher.cost) {
            filteredVouchers.push(findedVoucher);
          }
        } else {
          if (findedVoucher) {
            filteredVouchers.push(findedVoucher);
          }
        }
      });
    }
    if (appliedVouchers && appliedVouchers.length > 0) {
      const ids = appliedVouchers.map((i) => i.id);
      return filteredVouchers.filter((filteredVoucher) => ids.indexOf(filteredVoucher.id) === -1);
    }
    return filteredVouchers;
  };

  viewVoucher = (id) => {
    const { vouchers } = this.props;
    this.setState({ selectedVoucherModal: vouchers.find((v) => v.id === id) });
  };

  render() {
    const { __, vouchers,rewards,profile, qr_code } = this.props;
    const { selectedVoucher, voucherCode, showModal, disabledAppliedButton } = this.state;
    const appliedVouchers = Basket ? Basket.applied_vouchers : [];
    const filteredVouchers = this.filterVouchers(vouchers, appliedVouchers);
    const available_balance = profile.available_balance;
    let rewardsFromPoints = [];
    if(rewards.length === 1) {
      let n = 1
      while(n<=available_balance/rewards[0].stamps_required){
        rewardsFromPoints.push(rewards[0])
        n++
      }
    }
    return (
      <>
        <div className="flex-row-wrapper absolute-content">
          {getConfig().vouchersType === 2 ? null : (
            <div className="flex-min">
              <IonButton expand="block" color="secondary" onClick={() => this.showModal(true)}>
                {__('Voucher code')}
              </IonButton>
            </div>
          )}
          <Spacer />
          <Title className="centered">{__('Available Vouchers')}</Title>
          <Spacer />
          <div className="scrollable-y vaucher-list">
            {filteredVouchers.length === 0 ? (
              <p style={{ textAlign: 'center' }}>{__(`You don't have any applicable vouchers in your wallet`)}</p>
            ) : null}
            {filteredVouchers.length > 0 &&
              filteredVouchers.map((voucher, index) => {
                return (
                  <div
                    className={
                      selectedVoucher && (selectedVoucher.id === voucher.id) && (selectedVoucher.type === voucher.type) ? 'apply-voucher-box' : ''}
                    key={index}
                    onClick={() => this.setVoucher(voucher)}
                  >
                    <VoucherItem
                      key={'vc-' + index}
                      {...voucher}
                      __={__}
                      action={this.viewVoucher}
                    />
                  </div>
                );
              })}
              {rewardsFromPoints &&
                rewardsFromPoints.map((reward, index) => {
                  return (
                    <div
                      className={
                        selectedVoucher && (selectedVoucher.id === reward.id) && (selectedVoucher.type === reward.type) ? 'apply-voucher-box' : ''}
                      key={index}
                      onClick={() => this.setVoucher(reward)}
                    >
                      <VoucherItem
                      key={'vc-' + index}
                      {...reward}
                      __={__}
                      action={this.viewVoucher}
                    />
                    </div>
                  );
                })}
          </div>
          <div className="flex-min">
            <IonButton
              color="secondary"
              disabled={selectedVoucher ? false : true}
              onClick={() => {
                this.addVoucher();
              }}
              className={
                disabledAppliedButton ? 'box-button-color-tertiary' : 'box-button-color'
              }
              size="full"
              shape="round"
            >
              {__('Apply Vouchers')}
            </IonButton>
          </div>
        </div>
        <Modal
          className="apply-voucher-modal"
          isOpen={!!showModal}
          onDidDismiss={() => this.setState({ showModal: null })}
          title={__('Enter code')}
        >
          <div className="flex-row-wrapper">
            <div className="centered flex-row-wrapper">
              <div className=" centered scrollable-y">
                <NormalText>{__('Enter a valid voucher code below to receive your reward')}</NormalText>
                <div className="lefted padded voucher-item">
                  <IonItem style={{ '--min-height': '32px' }}>
                    <IonInput
                      value={voucherCode}
                      onIonChange={(e) => this.setState({ voucherCode: e.target.value })}
                      placeholder={__('Voucher code')}
                    />
                  </IonItem>
                  <FieldError
                    className="field-error"
                    value={__(this.state.formErrors.voucherCode)}
                  />
                </div>
              </div>
            </div>
            <div className="flex-min">
              <IonButton onClick={() => this.sendCode()} expand="full" color="secondary">
                {__('Submit')}
              </IonButton>
            </div>
          </div>
        </Modal>
        <Modal
          className="voucher-modal"
          isOpen={this.state.selectedVoucherModal !== null}
          onDidDismiss={() => this.setState({ selectedVoucherModal: null })}
          title={__('Voucher Info')}
        >
          {selectedVoucher && selectedVoucher.reward ? (
            <div className="centered">

              <VoucherItem
                {...selectedVoucher}
                __={__}
              />

              <Spacer size={2} />
              <div className="voucher-modal-content">
                {selectedVoucher.reward.name ? (
                  <>
                    <Title className="centered uppercase bold no-margin-bottom">{selectedVoucher.reward.name}</Title>
                  </>
                ) : null}
                {selectedVoucher.reward.small_print ? (
                  <div>
                    <NormalText>{selectedVoucher.reward.small_print}</NormalText>
                  </div>
                ) : null}
                {selectedVoucher.reward.expiry_date ? (
                  <div>
                    <NormalText>
                      {__('Expires:')}{' '}
                      {moment(selectedVoucher.reward.expiry_date, 'YYYY/MM/DD').format('DD/MM/YY')}
                    </NormalText>
                  </div>
                ) : null}
                <Spacer size={1} />
                <div onClick={() => forwardTo('/terms')}>
                  <StrongText className="bold">{__('Terms & Conditions')}</StrongText>
                </div>
                {selectedVoucher.reward.description ? (
                  <div>
                    <SmallText>{__(selectedVoucher.reward.description)}</SmallText>
                  </div>
                ) : null}
                <>
                  {isDefined(qr_code) ? (
                    <div className="qr-holder">
                      <QRCodeCanvas value={qr_code} size={150} />
                    </div>
                  ) : (
                    <div className="noQrCode">
                      <h5>{__('NO QR CODE')}</h5>
                    </div>
                  )}
                </>
              </div>
            </div>
          ) : null}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (store) => {
  const { rewards } = store.restaurants;
  const { profile } = store.profile;
  return {
    availableBalance: deepIsDefined(store, 'profile.profile.available_balance')
      ? store.profile.profile.available_balance
      : 0,
    basketUpadated: store.orders.basketUpdated,
    vouchers: store.profile.vouchers || null,
    applicableVouchers: store.profile.applicableVouchers || null,
    rewards: rewards || [],
    profile
  };
};

export default connect(mapStateToProps)(withTranslation(ApplyVouchers));
