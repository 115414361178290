import React from 'react';
import { Title, NormalText } from '../common';
import { isEmptyObject } from '../../lib/utils';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';

const { getRestauranName, getOrderDate, getOrderTime, getTableNumber } = Basket;

const findSelectedSlot = (dayInWeek, time) => {
  const restaurant = Basket.getRestaurant();
  const selectedDay = restaurant.delivery_times_json.slots[dayInWeek];
  const selectedSlot = selectedDay.find(
    (day) =>
      moment(day.start_time, 'HH:mm').format('HH:mm') === moment(time, 'HH:mm a').format('HH:mm'),
  );
  return (
    moment(selectedSlot.start_time, 'HH:mm').format('h:mm a') +
    ' - ' +
    moment(selectedSlot.end_time, 'HH:mm').format('h:mm a')
  );
};

const ContentHeader = ({
  __,
  deliveryOption,
  orderType,
  deliveryAddress,
  pickUpPoint,
  tableNumberHandler,
}) => {
  let dateName = moment(getOrderDate(), 'dddd Do MMM').format('ll');
  let dayInWeek = moment(getOrderDate(), 'dddd Do MMM').format('dddd');
  const deliveryLabel =
    deliveryOption &&
    (deliveryOption.label === 'Scheduled Delivery' || deliveryOption.label === 'Order for Delivery')
      ? 'Delivery Order'
      : deliveryOption.label;
  const locationCode = pickUpPoint && pickUpPoint.location_code ? pickUpPoint.location_code : '';
  if (!orderType || orderType === '') {
    return null;
  }
  if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-point') {
    return (
      <>
        <Title className="order-intro-title">{__(deliveryOption.label)}</Title>
        <NormalText className="auto-grid">
          <span>
            {__('Ordering for')} {__('drop-off')} {__('at')}{' '}
            <strong>
              {getRestauranName()} {`(#${locationCode})`},{' '}
            </strong>
          </span>{' '}
          <span>
            {dateName} {__('at')} {getOrderTime()}
            <br />
          </span>
        </NormalText>
      </>
    );
  } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'delivery') {
    return (
      <>
        <Title className="order-intro-title">{__(deliveryLabel)}</Title>
        <NormalText className=" thiner-text auto-grid">
          <span>
            {__('Ordering for')} {__('delivery')} {__('to')}{' '}
            <strong>{deliveryAddress.addressLine1}, </strong>
            {dateName}
          </span>{' '}
          {/* {deliveryAddress.place } {deliveryAddress.postalCode }  */}{' '}
          <span>
            {__('at')} {findSelectedSlot(dayInWeek, getOrderTime())}
            <br />
          </span>
        </NormalText>
      </>
    );
  } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'table') {
    return (
      <>
        <Title className="order-intro-title">{__(deliveryOption.label)}</Title>

        <NormalText>
          <span>
            {' '}
            {__('Ordering at')} {getRestauranName()} {__('for')}
          </span>{' '}
          <b
            onClick={() => {
              tableNumberHandler();
            }}
            style={{ cursor: 'pointer', color: 'var(--ion-color-secondary)' }}
          >
            {__('Table')} {getTableNumber() + '.'}
          </b>{' '}
          <span>{__('Your order will be brought to you as soon as possible.')}</span>
        </NormalText>
      </>
    );
  } else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'charter-delivery') {
    const date = dateName + __(' at ') + getOrderTime('h:mm a');
    const dateRes = dateName !== 'Invalid date' ? date : 'as soon as possible.';
    return (
      <>
        <Title className="order-intro-title">{__(deliveryOption.label)}</Title>
        <NormalText /*className="flex-col-wrapper flex-row-holder"*/ className="">
          <span>
            {__('Ordering at')} {getRestauranName()} {__('for delivery')} {__('to')}{' '}
            <strong>
              {deliveryAddress.addressLine1}, {deliveryAddress.addressLine2},{' '}
            </strong>{' '}
            {deliveryAddress.place}, {deliveryAddress.postalCode}
          </span>
          <span>
            {' '}
            {dateRes}
            <br />
          </span>
        </NormalText>
      </>
    );
  } else {
    const date = dateName + ' ' + __('at') + ' ' + getOrderTime('h:mm a');
    return (
      <>
        <Title className="order-intro-title">{__(orderType)}</Title>
        {Basket.is_asap ? (
          <NormalText className="flex-col-wrapper flex-row-holder ">
            {__('Ordering for collection from')} {getRestauranName()}
            {' - '}
            {'ASAP'}
          </NormalText>
        ) : (
          <NormalText className="flex-col-wrapper flex-row-holder ">
            {__('Ordering for collection from')} {getRestauranName()} {'on'} {date}
          </NormalText>
        )}
      </>
    );
  }
};

export default ContentHeader;
